import { Dispatch } from '@reduxjs/toolkit';
import invariant from 'invariant';
import cloneDeep from 'lodash.clonedeep';

import { ApiPreferredListingType } from 'api/types/ApiPreferredListingType';
import { ApiOrgSize } from 'modules/listing/api/org/types/ApiOrgSize';
import {
  addAdmin as apiAddAdmin,
  removeAdmin as apiRemoveAdmin,
} from 'modules/orgDashboard/api/orgDashboardApiAdmin';
import {
  approveAdminRequest as apiApproveAdminRequest,
  rejectAdminRequest as apiRejectAdminRequest,
} from 'modules/orgDashboard/api/orgDashboardApiAdminRequest';
import { orgDashboardApiFetchOrg } from 'modules/orgDashboard/api/orgDashboardApiFetchOrg';
import { orgDashboardApiDeleteOrg as apiDeleteOrg } from 'modules/orgDashboard/api/orgDashboardApiOrg';
import { addOwner as apiAddOwner } from 'modules/orgDashboard/api/orgDashboardApiOwner';
import { OrgDashboardApiOrg } from 'modules/orgDashboard/api/types/OrgDashboardApiOrg';
import {
  MainStoreUserEnvironmentAction,
  MainStoreUserEnvironmentState,
} from 'store/ducks/userEnvironment';
import { updateUserEnvironment } from 'store/ducks/userEnvironment.actions';
import { setError } from 'zustand-stores/errorStore';

import {
  FetchOrgRequestAction,
  FetchOrgSuccessAction,
  MainStoreOrgDashboardOrgsAction,
  MainStoreOrgDashboardOrgsState,
  PartialUpdateOrgAction,
  UpdateOrgAction,
} from './orgs';

export function fetchOrgRequest(orgId: string): FetchOrgRequestAction {
  return {
    type: 'ORG_DASHBOARD_FETCH_ORG_REQUEST',
    orgId,
  };
}

export function fetchOrgSuccess({
  org,
  user,
}: {
  org: OrgDashboardApiOrg;
  user: {
    preferredListingTypes: ApiPreferredListingType[];
    orgSize: ApiOrgSize;
  };
}): FetchOrgSuccessAction {
  return {
    type: 'ORG_DASHBOARD_FETCH_ORG_SUCCESS',
    org,
    user,
  };
}

export function fetchOrg(orgId: string, force = false) {
  return (
    dispatch: Dispatch<MainStoreOrgDashboardOrgsAction>,
    getState: () => { orgDashboard: { orgs: MainStoreOrgDashboardOrgsState } },
  ) => {
    const state = getState();
    const org = state.orgDashboard.orgs.byId[orgId];

    if (!org || force) {
      dispatch(fetchOrgRequest(orgId));

      return orgDashboardApiFetchOrg(orgId)
        .then((data) => dispatch(fetchOrgSuccess(data)))
        .catch((error) => {
          setError(error);
        });
    }
  };
}

export function updateOrg(org: OrgDashboardApiOrg): UpdateOrgAction {
  return {
    type: 'ORG_DASHBOARD_UPDATE_ORG',
    org,
  };
}

export function deleteOrg(orgId: string) {
  return async (
    dispatch: Dispatch<
      MainStoreUserEnvironmentAction | MainStoreOrgDashboardOrgsAction
    >,
    getState: () => { userEnvironment: MainStoreUserEnvironmentState },
  ) => {
    const userEnvironment = cloneDeep(getState().userEnvironment);
    // @ts-expect-error fix type errors
    userEnvironment.user.orgs = userEnvironment.user.orgs.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (o: any) => o.id !== orgId,
    );
    try {
      await apiDeleteOrg(orgId);
      dispatch({
        type: 'ORG_DASHBOARD_DELETE_ORG',
        orgId,
      });
      // @ts-expect-error fix type errors
      dispatch(updateUserEnvironment(userEnvironment));
    } catch (error) {
      setError(error as Error);
    }
  };
}

export function addAdmin(orgId: string, email: string, isOwner: boolean) {
  return async (
    dispatch: Dispatch<MainStoreOrgDashboardOrgsAction>,
    getState: () => { orgDashboard: { orgs: MainStoreOrgDashboardOrgsState } },
  ) => {
    const add = isOwner ? apiAddOwner : apiAddAdmin;
    const { user, invite } = await add(orgId, email);
    const org = getState().orgDashboard.orgs.byId[orgId];
    invariant(org, 'Invalid org id');

    if (user) {
      if (!org.admins.find((a) => a.id === user.id)) {
        dispatch(updateOrg({ ...org, admins: [...org.admins, user] }));
      } else {
        dispatch(
          updateOrg({
            ...org,
            admins: [user, ...org.admins.filter((a_1) => a_1.id !== user.id)],
          }),
        );
      }
    } else if (invite) {
      if (!org.invites.find((i) => i.id === invite.id)) {
        dispatch(updateOrg({ ...org, invites: [...org.invites, invite] }));
      }
    }

    return { user, invite };
  };
}

export function makeOwner(orgId: string, userId: string) {
  return async (
    dispatch: Dispatch<MainStoreOrgDashboardOrgsAction>,
    getState: () => { orgDashboard: { orgs: MainStoreOrgDashboardOrgsState } },
  ) => {
    const org = getState().orgDashboard.orgs.byId[orgId];
    const admin = org.admins.find((a) => a.id === userId);
    invariant(admin, 'User is not an admin');

    const { user } = await apiAddOwner(orgId, admin.email);
    invariant(user, 'Missing user');

    dispatch(
      updateOrg({
        ...org,
        admins: [user, ...org.admins.filter((a_1) => a_1.id !== user.id)],
      }),
    );
  };
}

export function removeAdmin(orgId: string, userId: string) {
  return async (
    dispatch: Dispatch<
      MainStoreOrgDashboardOrgsAction | MainStoreUserEnvironmentAction
    >,
    getState: () => {
      orgDashboard: { orgs: MainStoreOrgDashboardOrgsState };
      userEnvironment: MainStoreUserEnvironmentState;
    },
  ) => {
    const state = getState();
    const userEnvironment = cloneDeep(state.userEnvironment);

    // @ts-expect-error fix type errors
    userEnvironment.user.orgs = userEnvironment.user.orgs.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (o: any) => o.id !== orgId,
    );

    const org = state.orgDashboard.orgs.byId[orgId];
    const admin = org.admins.find((a) => a.id === userId);
    invariant(admin, 'User is not an admin');

    await apiRemoveAdmin(orgId, admin.id);
    dispatch(
      updateOrg({
        ...org,
        admins: org.admins.filter((a_1) => a_1.id !== admin.id),
      }),
    );

    // @ts-expect-error fix type errors
    if (userEnvironment.user.id === admin.id) {
      // @ts-expect-error fix type errors
      dispatch(updateUserEnvironment(userEnvironment));
    }
  };
}

export function approveAdminRequest(orgId: string, requestId: string) {
  return async (
    dispatch: Dispatch<MainStoreOrgDashboardOrgsAction>,
    getState: () => { orgDashboard: { orgs: MainStoreOrgDashboardOrgsState } },
  ) => {
    const org = getState().orgDashboard.orgs.byId[orgId];
    const adminRequest = org.adminRequests.find((r) => r.id === requestId);
    invariant(adminRequest, 'No admin request found');

    const admin = await apiApproveAdminRequest(orgId, requestId);
    dispatch(
      updateOrg({
        ...org,
        adminRequests: org.adminRequests.filter((r) => r.id !== requestId),
        // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type 'Admin'.
        admins: [admin, ...org.admins],
      }),
    );
  };
}

export function rejectAdminRequest(orgId: string, requestId: string) {
  return async (
    dispatch: Dispatch<MainStoreOrgDashboardOrgsAction>,
    getState: () => { orgDashboard: { orgs: MainStoreOrgDashboardOrgsState } },
  ) => {
    const org = getState().orgDashboard.orgs.byId[orgId];
    const adminRequest = org.adminRequests.find((r) => r.id === requestId);
    invariant(adminRequest, 'No admin request found');

    await apiRejectAdminRequest(orgId, requestId);
    dispatch(
      updateOrg({
        ...org,
        adminRequests: org.adminRequests.filter((r) => r.id !== requestId),
      }),
    );
  };
}

export function partialUpdateOrg(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>,
): PartialUpdateOrgAction {
  return {
    type: 'ORG_DASHBOARD_PARTIAL_UPDATE_ORG',
    values,
  };
}
