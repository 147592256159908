import {
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
  Ref,
  TouchEventHandler,
  forwardRef,
} from 'react';

import { AnimatedIconLoadable } from 'components/AnimatedIcon/AnimatedIconLoadable';
import { AnimatedIconName } from 'components/AnimatedIcon/AnimatedIconName';
import { Icon } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconName';
import { LottieAnimationOptions } from 'components/LottieAnimation/LottieAnimationOptions';
import { Color } from 'theme/theme';

import {
  ButtonWrapper,
  ChildrenContainer,
  IconContainer,
  StyledButton,
} from './Button.styled';
import { ButtonSize, ButtonVariant } from './Button.types';

const DEFAULT_VARIANT = 'primary';

type Props = {
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  children?: ReactNode;
  disabled?: boolean;

  role?: string;
  tabIndex?: number;
  title?: string;

  icon?:
    | { type: 'normalized'; name: IconName; color?: Color; size?: number }
    | {
        type: 'animated';
        name: AnimatedIconName;
        options?: LottieAnimationOptions;
      };
  iconPosition?: 'left' | 'right';
  size?: ButtonSize;
  variant?: ButtonVariant;
  fullWidth?: boolean;
  height?: number;
  noPadding?: boolean;

  onBlur?: FocusEventHandler<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onFocus?: FocusEventHandler<HTMLButtonElement>;
  onMouseOver?: MouseEventHandler<HTMLButtonElement>;
  onMouseOut?: MouseEventHandler<HTMLButtonElement>;
  onTouchStart?: TouchEventHandler<HTMLButtonElement>;
  onTouchMove?: TouchEventHandler<HTMLButtonElement>;
  onTouchEnd?: TouchEventHandler<HTMLButtonElement>;

  'aria-label'?: string;
  'data-qa-id'?: string;
  'data-qa-submitting'?: boolean;
  dataReady?: boolean;
};

export const Button = forwardRef(
  (
    {
      id,
      children,
      className,
      disabled,
      icon,
      iconPosition = 'left',
      role,
      size,
      tabIndex,
      title,
      type,
      variant,
      fullWidth,
      height,
      noPadding,
      onBlur,
      onClick,
      onFocus,
      onMouseOver,
      onMouseOut,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      'aria-label': ariaLabel,
      'data-qa-id': dataQaId,
      'data-qa-submitting': dataQaSubmitting,
      dataReady,
    }: Props,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const activeVariant = variant || DEFAULT_VARIANT;

    return (
      <StyledButton
        id={id}
        className={`${className || ''} ${activeVariant} ${size || 'medium'} ${
          fullWidth ? 'full-width' : ''
        } ${noPadding ? 'no-padding' : ''}`}
        data-qa-id={dataQaId}
        data-qa-submitting={dataQaSubmitting}
        data-ready={dataReady}
        aria-label={ariaLabel}
        type={type === undefined ? 'button' : type}
        disabled={disabled}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        ref={ref}
        role={role}
        tabIndex={tabIndex}
        title={title}
        style={{ height }}
      >
        <ButtonWrapper>
          {icon && iconPosition === 'left' && (
            <IconContainer>
              {icon.type === 'normalized' ? (
                <Icon
                  colorName={icon.color}
                  name={icon.name}
                  size={icon.size || 20}
                />
              ) : (
                // Use a loadable instead of the component directly to avoid bloating the bundle
                // Add wrapping div to avoid CLS
                <div style={{ width: 20, height: 20 }}>
                  <AnimatedIconLoadable
                    name={icon.name}
                    size={20}
                    options={icon.options}
                  />
                </div>
              )}
            </IconContainer>
          )}

          {children && <ChildrenContainer>{children}</ChildrenContainer>}

          {icon && iconPosition === 'right' && (
            <IconContainer>
              {icon.type === 'normalized' ? (
                <Icon colorName={icon.color} name={icon.name} size={20} />
              ) : (
                <AnimatedIconLoadable name={icon.name} size={20} />
              )}
            </IconContainer>
          )}
        </ButtonWrapper>
      </StyledButton>
    );
  },
);
