import {
  ApiSavedSearch,
  ApiSavedSearchUnsaved,
} from 'api/savedSearch/types/ApiSavedSearch';
import { del } from 'utils/http/del';
import { getJson } from 'utils/http/getJson';
import { postJson } from 'utils/http/postJson';
import { putJson } from 'utils/http/putJson';

export async function apiFetchSavedSearches(page: number): Promise<{
  savedSearches: ApiSavedSearch[];
  count: number;
  pageCount: number;
}> {
  const { savedSearches, count, pageCount } = await getJson<{
    savedSearches: ApiSavedSearch[];
    page: number;
    count: number;
    pageCount: number;
  }>(`/data/saved-searches?page=${page}`);

  return { savedSearches, count, pageCount };
}

export async function apiCreateSavedSearch({
  text,
  filters,
  location,
  index,
  radius,
}: ApiSavedSearchUnsaved): Promise<ApiSavedSearch> {
  const { savedSearch } = await postJson<{ savedSearch: ApiSavedSearch }>(
    '/data/saved-searches',
    {
      text,
      filters,
      radius,
      location,
      index,
    },
  );
  return savedSearch;
}

export async function apiEditSavedSearch(
  id: string,
  { name, notifyByEmail }: { name: string; notifyByEmail: boolean },
): Promise<ApiSavedSearch> {
  const { savedSearch } = await putJson<{ savedSearch: ApiSavedSearch }>(
    `/data/saved-searches/${id}`,
    {
      name,
      notifyByEmail,
    },
  );
  return savedSearch;
}

export function apiDeleteSavedSearch(searchId: string) {
  return del(`/data/saved-searches/${searchId}`);
}
