import interpolateComponents from '@automattic/interpolate-components';

import { Box } from 'components/Box';
import { PrimaryButton } from 'components/Button';
import { TrackedLink } from 'components/Link';

import { AcceptCookiesContainer } from './AcceptCookies.styled';

type Props = {
  onAcceptCookies: () => void;
  submitting: boolean;
};

export function AcceptCookies({ onAcceptCookies, submitting }: Props) {
  return (
    <AcceptCookiesContainer data-qa-id="accept-cookies-alert">
      <div>
        {interpolateComponents({
          mixedString:
            /* TRANSLATORS: accept cookie message in page footer for EU */
            getText(
              'This site uses cookies. By continuing to use our site, you agree to our use of cookies as described in our {{link}}Cookie Policy{{/link}}.',
            ),
          components: {
            link: (
              <TrackedLink
                href="https://info.idealist.org/cookie-policy/"
                eventName="Resource Page"
              />
            ),
          },
        })}
      </div>

      <Box ml={[0, 0, 0, 3]} mt={[2, 0, 0, 0]}>
        <PrimaryButton
          size="small"
          onClick={onAcceptCookies}
          disabled={submitting}
          data-qa-id="accept-cookies-button"
        >
          {
            /* TRANSLATORS: accept cookies button text */
            getText('OK')
          }
        </PrimaryButton>
      </Box>
    </AcceptCookiesContainer>
  );
}
