import { ApiNotification } from 'api/notification/types/ApiNotification';
import { MappedNotification } from 'types/mappedNotifications/MappedNotification';

// Types

export type MainStoreUserNotificationsState = {
  notifications: (ApiNotification | MappedNotification)[];
  isFetching?: boolean;
  isLoaded: boolean;
};

export type FetchNotificationsRequestAction = {
  type: 'NOTIFICATIONS_FETCH_REQUEST';
};

export type FetchNotificationsSuccessAction = {
  type: 'NOTIFICATIONS_FETCH_SUCCESS';
  notifications: (ApiNotification | MappedNotification)[];
};

export type MarkNotificationsViewedRequestAction = {
  type: 'NOTIFICATIONS_MARK_VIEWED_REQUEST';
};

export type MarkNotificationsViewedSuccessAction = {
  type: 'NOTIFICATIONS_MARK_VIEWED_SUCCESS';
  notifications: (ApiNotification | MappedNotification)[];
};

export type MarkNotificationsReadRequestAction = {
  type: 'NOTIFICATIONS_MARK_READ_REQUEST';
};

export type MarkNotificationsReadSuccessAction = {
  type: 'NOTIFICATIONS_MARK_READ_SUCCESS';
  notifications: (ApiNotification | MappedNotification)[];
};

export type MarkNotificationsDismissedRequestAction = {
  type: 'NOTIFICATIONS_MARK_DISMISSED_REQUEST';
};

export type MarkNotificationsDismissedSuccessAction = {
  type: 'NOTIFICATIONS_MARK_DISMISSED_SUCCESS';
  notifications: (ApiNotification | MappedNotification)[];
};

export type MainStoreUserNotificationsAction =
  | FetchNotificationsRequestAction
  | FetchNotificationsSuccessAction
  | MarkNotificationsViewedRequestAction
  | MarkNotificationsViewedSuccessAction
  | MarkNotificationsReadRequestAction
  | MarkNotificationsReadSuccessAction
  | MarkNotificationsDismissedRequestAction
  | MarkNotificationsDismissedSuccessAction;

// Reducer

export function userNotificationsReducer(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MainStoreUserNotificationsState = {
    notifications: [],
    isLoaded: false,
  },
  action: MainStoreUserNotificationsAction,
) {
  switch (action.type) {
    case 'NOTIFICATIONS_FETCH_REQUEST':
      return { ...state, isFetching: true };

    case 'NOTIFICATIONS_FETCH_SUCCESS':
      return {
        ...state,
        notifications: action.notifications,
        isFetching: false,
        isLoaded: true,
      };

    case 'NOTIFICATIONS_MARK_VIEWED_SUCCESS':
      return {
        ...state,
        notifications: action.notifications,
      };

    case 'NOTIFICATIONS_MARK_READ_SUCCESS':
      return {
        ...state,
        notifications: action.notifications,
      };

    case 'NOTIFICATIONS_MARK_DISMISSED_SUCCESS':
      return {
        ...state,
        notifications: action.notifications,
      };

    default:
      return state;
  }
}
