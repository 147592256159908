import { combineReducers } from '@reduxjs/toolkit';

import { mainStoreOrgDashboardReducer } from './ducks/orgDashboard/mainStoreOrgDashboardReducer';
import { paymentMethodsReducer } from './ducks/paymentMethods';
import { searchRadiusReducer } from './ducks/searchRadius';
import { userEnvironmentReducer } from './ducks/userEnvironment';
import { userLocationReducer } from './ducks/userLocation';
import { userNotificationsReducer } from './ducks/userNotifications';
import { mainSearchReducer } from './mainSearch/mainSearch';
import { postSearchReducer } from './postSearch/postSearch';

const reducers = {
  userEnvironment: userEnvironmentReducer,
  userNotifications: userNotificationsReducer,
  userLocation: userLocationReducer,
  paymentMethods: paymentMethodsReducer,
  mainSearch: mainSearchReducer,
  searchRadius: searchRadiusReducer,
  orgDashboard: mainStoreOrgDashboardReducer,
  postSearch: postSearchReducer,
};

export const mainRootReducer = combineReducers(reducers);
