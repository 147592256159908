import { Dispatch } from '@reduxjs/toolkit';

import { SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { EMPTY_SEARCH_LOCATION } from 'utils/constants/general/emptySearchLocation';
import { setItem } from 'utils/localStorage';

import { MainStoreMainSearchState, SortType } from './MainStoreMainSearchState';
import { extractLocationProperties } from './extractLocationProperties';
import { MainStoreMainSearchAction } from './mainSearch';

// Actions
export function updateSearchLocation(
  location: SearchLocation,
  didUserChange: boolean,
) {
  return (dispatch: Dispatch<MainStoreMainSearchAction>) => {
    if (didUserChange) {
      trackEvent(
        'Changed Search Location',
        extractLocationProperties(location),
      );
    }

    setItem('searchLocation', location);
    return dispatch({
      type: 'UPDATE_SEARCH_LOCATION',
      location: { ...EMPTY_SEARCH_LOCATION, ...location },
    });
  };
}

export function clearSearchLocation(didUserChange: boolean) {
  return (dispatch: Dispatch<MainStoreMainSearchAction>) => {
    if (didUserChange) {
      trackEvent('Cleared Search Location');
    }

    setItem('searchLocation', EMPTY_SEARCH_LOCATION);
    dispatch({
      type: 'UPDATE_SEARCH_LOCATION',
      location: EMPTY_SEARCH_LOCATION,
    });
  };
}

export function selectedOption(
  state: { mainSearch: MainStoreMainSearchState },
  facetId: SearchFacetId,
) {
  const options = state.mainSearch.filters[facetId];
  return Array.isArray(options) ? options[0] : options;
}

export function setSearchFilters(filtersById: SearchFiltersById) {
  return { type: 'SEARCH_SET_FILTERS', filters: filtersById };
}

export function setSearchQuery(query: string) {
  return { type: 'SEARCH_SET_QUERY', query };
}

export function setSearchSort(sortParam: SortType) {
  return { type: 'SEARCH_SET_SORT', sortParam };
}

export function setPageIndex(pageIndex: number) {
  return { type: 'SEARCH_SET_PAGE_INDEX', pageIndex };
}
