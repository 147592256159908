import { MouseEvent, ReactNode } from 'react';

import { Link as VersionedRouterLink } from 'components/RouterLink';

import { HeaderDropdownTriggerHolder } from './DropdownHeaderTrigger.styled';

type Props = {
  children: ReactNode;
  variant: 'grey' | 'blue' | 'secondary';
  minWidth?: number;
  smallHorizontalPadding?: boolean;
  centerHorizontally?: boolean;
  qaId?: string;
  ariaLabel?: string;
  asRouterLink?: {
    to: string;
  };
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  roundBottomWhenOpen?: boolean;
};

/**
 * This component is sort of a button - it doesn't use an actual `<button>` because the dropdown is triggered
 * via `<summary>` `<details>`, not `onClick`
 */
export function DropdownHeaderTrigger({
  children,
  variant,
  minWidth,
  smallHorizontalPadding,
  centerHorizontally,
  qaId,
  ariaLabel,
  asRouterLink,
  onClick,
  roundBottomWhenOpen,
}: Props) {
  return (
    // Can be a `RouterLink` or a `div` - this is difficult to type
    <HeaderDropdownTriggerHolder<typeof VersionedRouterLink>
      as={asRouterLink ? VersionedRouterLink : undefined}
      // Not exactly the right type, but close enough
      to={asRouterLink?.to as string}
      $variant={variant}
      $smallHorizontalPadding={Boolean(smallHorizontalPadding)}
      $roundBottomWhenOpen={Boolean(roundBottomWhenOpen)}
      $centerHorizontally={Boolean(centerHorizontally)}
      onClick={onClick}
      style={{ minWidth }}
      data-qa-id={qaId}
      aria-label={ariaLabel}
    >
      {children}
    </HeaderDropdownTriggerHolder>
  );
}
