import { MainStoreMainSearchState } from 'store/mainSearch/MainStoreMainSearchState';
import { extractLocationProperties } from 'store/mainSearch/extractLocationProperties';

import { searchGetFiltersForAnalytics } from './searchGetFiltersForAnalytics';

type Args = {
  mainSearch: MainStoreMainSearchState;
  radius: string;
  searchFrom?: string;
};

export function searchGetTrackingDataFromState({
  mainSearch,
  radius,
  searchFrom,
}: Args) {
  const { filters, query, pageIndex, resultsByPage, searchLocation } =
    mainSearch;

  if (searchLocation) {
    const results = resultsByPage[pageIndex.toString()] || {
      nbHits: 0,
      nbPages: 0,
    };

    return {
      current_page: pageIndex + 1,
      geo_location_enabled: Boolean(searchLocation.latitude),
      ...searchGetFiltersForAnalytics({ filtersById: filters }),
      search_query: query,
      search_radius: radius,
      number_of_results: results.nbHits,
      number_of_pages: results.nbPages,
      search_from: searchFrom,
      ...extractLocationProperties(searchLocation),
    };
  }

  return {};
}
