import { ApiApplicantProfileResponse } from 'api/applicantProfile/apiApplicantProfile';
import { ApiApplicantProfile } from 'api/applicantProfile/types/ApiApplicantProfile';
import { ApiSavedSearch } from 'api/savedSearch/types/ApiSavedSearch';
import {
  ApiEnvironment,
  ApiUser,
  ApiUserEnvironment,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { removeId } from 'utils/functional/array/removeId';

export type FetchUserEnvironmentRequestAction = {
  type: 'USER_ENVIRONMENT_FETCH_REQUEST';
};

export type FetchUserEnvironmentSuccessAction = {
  type: 'USER_ENVIRONMENT_FETCH_SUCCESS';
  userEnvironment: ApiUserEnvironment;
};

export type UpdateUserEnvironmentUserAction = {
  type: 'UPDATE_USER_ENVIRONMENT_USER';
  user: ApiUser;
};

export type UpdateUserEnvironmentEnvironmentAction = {
  type: 'UPDATE_USER_ENVIRONMENT_ENVIRONMENT';
  environment: ApiEnvironment;
};

export type SaveListingAction = {
  type: 'SAVE_LISTING';
  listingId: string;
  listingType: string;
};

export type UnsaveListingAction = {
  type: 'UNSAVE_LISTING';
  listingId: string;
};

export type SaveSearchAction = {
  type: 'SAVE_SEARCH';
  savedSearch: ApiSavedSearch;
};

export type EditSavedSearchAction = {
  type: 'EDIT_SAVED_SEARCH';
  editedSavedSearch: ApiSavedSearch;
};

export type UnsaveSearchAction = {
  type: 'UNSAVE_SEARCH';
  searchId: string;
};

// Used to switch between various states of saving search
export type UpdateSavingSearchStatusAction = {
  type: 'UPDATE_SAVING_SEARCH_STATUS';
  savingSearchStatus: 'UNSAVED' | 'EDITING' | 'SAVED';
};

export type FetchApplicantRequestAction = {
  type: 'APPLICANT_PROFILE_FETCH_REQUEST';
};

export type FetchApplicantSuccessAction = {
  type: 'APPLICANT_PROFILE_FETCH_SUCCESS';
  applicantProfileResponse: ApiApplicantProfileResponse;
};

export type FetchApplicantFailureAction = {
  type: 'APPLICANT_PROFILE_FETCH_FAILURE';
};

export type MainStoreUserEnvironmentAction =
  | FetchUserEnvironmentRequestAction
  | FetchUserEnvironmentSuccessAction
  | UpdateUserEnvironmentUserAction
  | UpdateUserEnvironmentEnvironmentAction
  | SaveListingAction
  | UnsaveListingAction
  | SaveSearchAction
  | EditSavedSearchAction
  | UpdateSavingSearchStatusAction
  | UnsaveSearchAction
  | FetchApplicantRequestAction
  | FetchApplicantSuccessAction
  | FetchApplicantFailureAction;

export type MainStoreUserEnvironmentState = {
  environment?: ApiEnvironment;
  isEnvironmentFetching: boolean;
  isEnvironmentLoaded: boolean;
  user?: ApiUser | null;
  isUserFetching: boolean;
  isUserLoaded: boolean;
  applicantProfile?: ApiApplicantProfile;
  isApplicantProfileLoaded: boolean;
  isApplicantProfileFetching: boolean;
};

export function userEnvironmentReducer(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MainStoreUserEnvironmentState = {
    user: undefined,
    environment: undefined,
    applicantProfile: undefined,
    isEnvironmentFetching: false,
    isEnvironmentLoaded: false,
    isUserFetching: false,
    isUserLoaded: false,
    isApplicantProfileLoaded: false,
    isApplicantProfileFetching: false,
  },
  action: MainStoreUserEnvironmentAction,
): MainStoreUserEnvironmentState {
  switch (action.type) {
    case 'USER_ENVIRONMENT_FETCH_REQUEST':
      return { ...state, isEnvironmentFetching: true, isUserFetching: true };

    case 'USER_ENVIRONMENT_FETCH_SUCCESS':
      return {
        ...state,
        isEnvironmentFetching: false,
        isEnvironmentLoaded: true,
        isUserFetching: false,
        isUserLoaded: true,
        user: action.userEnvironment.user,
        environment: action.userEnvironment.environment,
      };

    case 'UPDATE_USER_ENVIRONMENT_USER':
      return {
        ...state,
        isUserFetching: false,
        isUserLoaded: true,
        user: action.user,
      };

    case 'UPDATE_USER_ENVIRONMENT_ENVIRONMENT':
      return {
        ...state,
        isEnvironmentFetching: false,
        isEnvironmentLoaded: true,
        environment: action.environment,
      };

    case 'SAVE_LISTING': {
      const { listingId, listingType } = action;

      if (
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        state.user.savedListings.find((listing) => listing.id === listingId)
      ) {
        // already saved, no-op
        return state;
      }

      return {
        ...state,
        // @ts-expect-error TS(2322): Type '{ savedListings: { id: string; type: string;... Remove this comment to see the full error message
        user: {
          ...state.user,
          savedListings: [
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            ...(state.user.savedListings || []),
            {
              id: listingId,
              type: listingType,
            },
          ],
        },
      };
    }

    case 'UNSAVE_LISTING': {
      const { listingId } = action;
      return {
        ...state,
        // @ts-expect-error TS(2322): Type '{ savedListings: { id: string; type: string;... Remove this comment to see the full error message
        user: {
          ...state.user,
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          savedListings: removeId(state.user.savedListings, listingId),
        },
      };
    }

    case 'SAVE_SEARCH': {
      const { savedSearch } = action;

      if (
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        state.user.savedSearches.find((search) => search.id === savedSearch.id)
      ) {
        // already saved, no-op
        return state;
      }

      return {
        ...state,
        // @ts-expect-error TS(2322): Type '{ savedSearches: ApiSavedSearch[]; id?: stri... Remove this comment to see the full error message
        user: {
          ...state.user,
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          savedSearches: [...state.user.savedSearches, savedSearch],
        },
      };
    }

    case 'EDIT_SAVED_SEARCH': {
      const { editedSavedSearch } = action;

      if (!state.user) return state;

      const updatedSavedSearches = state.user?.savedSearches.map((search) =>
        search.id === editedSavedSearch.id ? editedSavedSearch : search,
      );

      return {
        ...state,
        user: {
          ...state.user,
          savedSearches: updatedSavedSearches,
        },
      };
    }

    case 'UPDATE_SAVING_SEARCH_STATUS': {
      const { savingSearchStatus } = action;
      return {
        ...state,
        // @ts-expect-error Figure out user type or move saved search out of Redux
        user: {
          ...state.user,
          savingSearchStatus,
        },
      };
    }

    case 'UNSAVE_SEARCH': {
      const { searchId } = action;
      return {
        ...state,
        // @ts-expect-error TS(2322): Type '{ savedSearches: ApiSavedSearch[]; id?: stri... Remove this comment to see the full error message
        user: {
          ...state.user,
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          savedSearches: removeId(state.user.savedSearches, searchId),
        },
      };
    }

    case 'APPLICANT_PROFILE_FETCH_REQUEST':
      return { ...state, isApplicantProfileFetching: true };

    case 'APPLICANT_PROFILE_FETCH_SUCCESS':
      return {
        ...state,
        ...action.applicantProfileResponse.userEnvironment,
        // @ts-expect-error TS(2322): Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
        user: state.user
          ? {
              ...state.user,
              ...action.applicantProfileResponse.userEnvironment?.user,
              isRecruitable: action.applicantProfileResponse.isRecruitable,
              resumeId: action.applicantProfileResponse.resumeId,
            }
          : undefined,
        applicantProfile:
          action.applicantProfileResponse.applicantProfile || undefined,
        isApplicantProfileFetching: false,
        isApplicantProfileLoaded: true,
      };

    case 'APPLICANT_PROFILE_FETCH_FAILURE':
      return { ...state, isApplicantProfileFetching: false };

    default:
      return state;
  }
}
