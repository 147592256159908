import { css } from 'styled-components';

import { TooltipPosition } from './TooltipPosition';
import { TooltipSize } from './TooltipSize';

export const arrowPositionStyles = (
  placement: TooltipPosition,
  targetWidth: number,
  size: TooltipSize,
) => {
  const arrowSize = size === 'large' ? '20px' : '10px';

  const commonStyles = css`
    &::before {
      width: ${arrowSize};
      height: ${arrowSize};
      transform: rotate(45deg) skew(12deg, 12deg);
    }
  `;

  const topStyles = css`
    &::before {
      bottom: calc(-${arrowSize} / 2);
      border-top: none;
    }
  `;

  const bottomStyles = css`
    &::before {
      top: calc(-${arrowSize} / 2);
      border-bottom: none;
    }
  `;

  const centerStyles = css`
    &::before {
      left: 50%;
      margin-left: calc(-${arrowSize} / 2);
      ${placement === 'bottom-center'
        ? 'border-right: none'
        : 'border-left: none'};
    }
  `;

  const leftStyles = css`
    &::before {
      right: ${targetWidth / 2}px;
      margin-right: calc(-${arrowSize} / 2);
      border-right: none;
    }
  `;

  const rightStyles = css`
    &::before {
      left: ${targetWidth / 2}px;
      margin-left: calc(-${arrowSize} / 2);
      border-right: none;
    }
  `;

  switch (placement) {
    case 'bottom-center':
      return css`
        ${commonStyles}
        ${bottomStyles}
        ${centerStyles}
      `;
    case 'bottom-left':
      return css`
        ${commonStyles}
        ${bottomStyles}
        ${leftStyles}
      `;
    case 'bottom-right':
      return css`
        ${commonStyles}
        ${bottomStyles}
        ${rightStyles}
      `;

    case 'top-center':
      return css`
        ${commonStyles}
        ${topStyles}
        ${centerStyles}
      `;
    case 'top-left':
      return css`
        ${commonStyles}
        ${topStyles}
        ${leftStyles}
      `;
    case 'top-right':
      return css`
        ${commonStyles}
        ${topStyles}
        ${rightStyles}
      `;
    default:
      return null;
  }
};
