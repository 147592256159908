import { MouseEventHandler, ReactNode, useEffect, useRef } from 'react';

import { setupLinkTracker } from 'utils/analytics/setup/setupLinkTracker';

import { Link } from './Link';

type Props = {
  'aria-label'?: string;
  className?: string;
  children?: ReactNode;
  'data-qa-id'?: string;
  eventName?: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventProperties?: Record<string, any>;
  href: string;
  waitForHref?: boolean;
  tabIndex?: number;
  target?: string;
  title?: string;
  rel?: string;
  onClick?: MouseEventHandler;
  color?: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateTracker = (eventName: any, eventProperties: any, linkRef: any) => {
  if (eventName && linkRef?.current) {
    return setupLinkTracker(eventName, eventProperties)(linkRef.current);
  }
};

export function TrackedLink({ waitForHref, ...props }: Props) {
  const linkRef = useRef(null);
  useEffect(() => {
    const clickHandler = updateTracker(
      props.eventName,
      props.eventProperties,
      linkRef,
    );
    const el = linkRef.current;
    return () => {
      if (el && clickHandler) {
        // @ts-expect-error TS(2339): Property 'removeEventListener' does not exist on t... Remove this comment to see the full error message
        el.removeEventListener('click', clickHandler);
      }
    };
  }, [props.eventName, props.eventProperties]);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link key={props.eventName} {...props} ref={linkRef} />;
}
