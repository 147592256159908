import { Outlet, useParams } from 'react-router-dom';

import { ApiOrgId } from 'api/types/ApiTypedId';
import { OrgDashboardOrgContext } from 'modules/orgDashboard/contexts/OrgDashboardOrgContext';
import { useMainSelector } from 'store/hooks/useMainSelector';

export function OrgDashboardOrgContextProviderContainer() {
  const { orgId } = useParams() as unknown as { orgId: ApiOrgId };
  const org = useMainSelector((state) => state.orgDashboard.orgs.byId[orgId]);

  return (
    <OrgDashboardOrgContext.Provider
      value={org && !org.isFetching ? org : undefined}
    >
      <Outlet key={orgId} />
    </OrgDashboardOrgContext.Provider>
  );
}
