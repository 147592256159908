import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { GradFairsBannerLoadable } from 'components/Banners/GradFairsBannerLoadable';
import { ResumeDatabaseBannerLoadable } from 'components/Banners/ResumeDatabaseBannerLoadable';
import { SalarySurveyBannerLoadable } from 'components/Banners/SalarySurveyBannerLoadable';
import { configValue } from 'config/appConfigUtils';
import { ClientOnly } from 'containers/ClientOnly';
import { useShouldPromoteSalarySurvey } from 'modules/salaries/hooks/useShouldPromoteSalarySurvey';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { LocalNotificationStatus } from 'types/LocalNotification/LocalNotificationStatus';
import { LocalProductNotificationType } from 'types/LocalNotification/LocalProductNotificationType';
import { promoteResumeDatabase } from 'utils/resumeDatabase';
import { AdType, setAdType, useAdType } from 'zustand-stores/layoutStore';
import { useLocalNotification } from 'zustand-stores/localNotificationsStore';

import { BannerAdsBox } from './BannerAds.styled';

export function BannerAds() {
  const { adType } = useAdType();

  const userEnvironment = useUserEnvironment();
  const { isUserLoaded, isEnvironmentLoaded } = userEnvironment;
  const location = useLocation();

  const salarySurveyNotification = useLocalNotification(
    LocalProductNotificationType.SALARY_SURVEY_CTA,
  );

  const resumeDatabaseNotification = useLocalNotification(
    LocalProductNotificationType.UPLOAD_RESUME,
  );

  const gradFairsNotification = useLocalNotification(
    LocalProductNotificationType.GRAD_FAIRS_BANNER,
  );

  const showSalarySurveyAd = useShouldPromoteSalarySurvey();
  const showGradFairsAd = configValue('idealist', 'fairs', 'adsEnabled');

  const showGradFairsBanner =
    showGradFairsAd &&
    gradFairsNotification?.status === LocalNotificationStatus.NEW;

  const showSalarySurveyBanner =
    !location.pathname.includes('/nonprofit-salary-explorer') &&
    showSalarySurveyAd &&
    !(['GRAD_FAIRS', 'RESUME_DB'] as AdType[]).includes(adType) &&
    salarySurveyNotification?.status === LocalNotificationStatus.NEW;

  useEffect(() => {
    if (
      isUserLoaded &&
      isEnvironmentLoaded &&
      adType === null &&
      showGradFairsBanner
    ) {
      setAdType('GRAD_FAIRS');
    }
  }, [
    isUserLoaded,
    isEnvironmentLoaded,
    adType,
    showSalarySurveyBanner,
    showGradFairsBanner,
  ]);

  useEffect(() => {
    if (
      isUserLoaded &&
      isEnvironmentLoaded &&
      adType !== 'SALARY_SURVEY' &&
      !showGradFairsBanner &&
      showSalarySurveyBanner
    ) {
      setAdType('SALARY_SURVEY');
    }
  }, [
    isUserLoaded,
    isEnvironmentLoaded,
    adType,
    showSalarySurveyBanner,
    showGradFairsBanner,
  ]);

  const showResumeDatabaseBanner =
    !showSalarySurveyBanner &&
    !(['GRAD_FAIRS', 'SALARY_SURVEY'] as AdType[]).includes(adType) &&
    promoteResumeDatabase({
      userEnvironment,
      allowLoggedOut: true,
    }) &&
    resumeDatabaseNotification?.status === LocalNotificationStatus.NEW;

  useEffect(() => {
    if (
      isUserLoaded &&
      isEnvironmentLoaded &&
      adType !== 'RESUME_DB' &&
      showResumeDatabaseBanner
    ) {
      setAdType('RESUME_DB');
    }
  }, [isUserLoaded, isEnvironmentLoaded, adType, showResumeDatabaseBanner]);

  return (
    <BannerAdsBox
      data-qa-id="ads-container"
      mt={[60, null, null, 0]}
      mb={[-60, null, null, 0]}
    >
      <ClientOnly>
        {showGradFairsBanner ? <GradFairsBannerLoadable /> : null}
        {showResumeDatabaseBanner ? <ResumeDatabaseBannerLoadable /> : null}
        {showSalarySurveyBanner ? <SalarySurveyBannerLoadable /> : null}
      </ClientOnly>
    </BannerAdsBox>
  );
}
