import invariant from 'invariant';

import { PostSearchFacetData } from 'modules/postSearch/types/PostSearchFacetData';
import { PostSearchQueryFilters } from 'modules/postSearch/types/PostSearchQueryFilters';
import { PostSearchFacetKeys } from 'store/postSearch/postSearch';

function getFilterValue(
  facet: PostSearchFacetData,
  paramValue: string | string[],
): string[] {
  invariant(facet.type === 'array', 'Facet type must be `array`');

  return Array.isArray(paramValue) ? paramValue : [paramValue];
}

export function postSearchGetInitialFilters(
  facets: Record<
    keyof Omit<PostSearchQueryFilters, 'type'>,
    PostSearchFacetData
  >,
  queryParams: Readonly<Record<string, string>>,
) {
  const initialFacetKeys = Object.keys(facets) as PostSearchFacetKeys[];

  const initialFilters = {} as PostSearchQueryFilters;

  initialFacetKeys
    .filter((key) => queryParams[facets[key].name])
    .forEach((key) => {
      const paramValue = queryParams[facets[key].name];

      initialFilters[key] = getFilterValue(facets[key], paramValue);
    });

  return initialFilters;
}
