import { useCallback } from 'react';

import { searchGetTrackingDataFromState } from 'modules/search/helpers/analytics/searchGetTrackingDataFromState';
import { getParsedSearchQuery } from 'modules/search/helpers/getParsedSearchQuery';
import { SearchPageLocationState } from 'modules/search/pages/Search/types/SearchPageLocationState';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { SortType } from 'store/mainSearch/MainStoreMainSearchState';
import { clearSearchLocation } from 'store/mainSearch/mainSearch.actions';
import { trackEvent } from 'utils/analytics/track/trackEvent';

import { useSearchPageActionsHelpers } from './useSearchPageActionsHelpers';

type Args = {
  isInSearchLandingPage: boolean;
};

export function useSearchPageActions({ isInSearchLandingPage }: Args) {
  const dispatch = useMainDispatch();

  const { getState, updateQueryParams, resetQueryParams } =
    useSearchPageActionsHelpers({
      isInSearchLandingPage,
    });

  // Navigate
  const navigateToDerivedUrlFromState = useCallback(() => {
    updateQueryParams({});
  }, [updateQueryParams]);

  // Query
  const setSearchUrlQueryOrLocationChanged = useCallback(
    (
      data: { query?: string; triggeredByRemoteLocationChange: boolean },
      locationState?: { searchFrom?: string },
    ) => {
      const state = getState();

      const queryParamsToUpdate: {
        q?: string;
        locationType?: 'REMOTE';
        page: undefined;
        radius?: undefined;
      } = {
        page: undefined,
      };

      const composedLocationState: SearchPageLocationState = {
        ...locationState,
      };

      if (typeof data.query === 'string') {
        const { parsedQuery, locationType, remoteKeywords } =
          getParsedSearchQuery({ query: data.query });

        if (parsedQuery !== state.mainSearch.query) {
          trackEvent('Changed Search Query', {
            ...searchGetTrackingDataFromState({
              mainSearch: state.mainSearch,
              radius: state.searchRadius.value,
            }),
            new_query: data.query,
          });

          queryParamsToUpdate.q = parsedQuery;
          if (locationType) queryParamsToUpdate.locationType = locationType;

          composedLocationState.remoteKeywords = remoteKeywords;
        }
      }

      if (data.triggeredByRemoteLocationChange)
        queryParamsToUpdate.locationType = 'REMOTE';

      if (!state.mainSearch.searchLocation?.text)
        queryParamsToUpdate.radius = undefined;

      updateQueryParams(queryParamsToUpdate, composedLocationState);
    },
    [getState, updateQueryParams],
  );

  // Page index
  const setSearchUrlPageIndex = useCallback(
    (pageIndex: number) => {
      const state = getState();
      if (pageIndex === state.mainSearch.pageIndex) return;

      updateQueryParams({ page: pageIndex === 0 ? undefined : pageIndex + 1 });

      trackEvent('Changed Search Page Number', {
        ...searchGetTrackingDataFromState({
          mainSearch: state.mainSearch,
          radius: state.searchRadius.value,
        }),
        new_page: pageIndex,
      });
    },
    [getState, updateQueryParams],
  );

  // Sort
  const setSearchUrlSort = useCallback(
    (sortParam: SortType) => {
      const state = getState();
      if (state.mainSearch.sort === sortParam) return;

      updateQueryParams({
        pageIndex: undefined,
        sort: sortParam === 'relevance' ? undefined : 'newest',
      });

      trackEvent('Changed Search Sort', {
        ...searchGetTrackingDataFromState({
          mainSearch: state.mainSearch,
          radius: state.searchRadius.value,
        }),
        sort_order: sortParam,
      });
    },
    [getState, updateQueryParams],
  );

  // Radius
  const setSearchUrlRadius = useCallback(
    (radius: string) => {
      const state = getState();
      if (state.searchRadius.value === radius) return;

      updateQueryParams({ radius: radius === 'auto' ? undefined : radius });

      trackEvent('Changed Search Radius', {
        ...searchGetTrackingDataFromState({
          mainSearch: state.mainSearch,
          radius: state.searchRadius.value,
        }),
        radius,
      });
    },
    [getState, updateQueryParams],
  );

  const clearSearchUrl = useCallback(() => {
    const state = getState();

    dispatch(clearSearchLocation(true));
    resetQueryParams({
      // Reset everything, except the search query
      q: state.mainSearch.query,
    });
  }, [dispatch, getState, resetQueryParams]);

  return {
    navigateToDerivedUrlFromState,
    setSearchUrlQueryOrLocationChanged,
    setSearchUrlPageIndex,
    setSearchUrlSort,
    setSearchUrlRadius,
    clearSearchUrl,
  };
}
