import { MouseEventHandler } from 'react';

import { Button } from 'components/Button/Button';

import { ClearButtonHolder } from './ClearButton.styled';

type Props = {
  ariaLabel?: string;
  onClear?: MouseEventHandler<HTMLButtonElement>;
  qaId?: string;
};

export function ClearButton({ ariaLabel, onClear, qaId }: Props) {
  return (
    <ClearButtonHolder>
      <Button
        data-qa-id={qaId}
        variant="tertiary-grey"
        size="tiny"
        icon={{ name: 'close', type: 'normalized', size: 16 }}
        onClick={onClear}
        aria-label={ariaLabel || getText('Clear input')}
        noPadding
      />
    </ClearButtonHolder>
  );
}
