import { keyframes } from 'styled-components';

import { TooltipPosition } from './TooltipPosition';
import { TooltipSize } from './TooltipSize';
import { placementStyles } from './placementStyles';

export const generateKeyframes = (
  placement: TooltipPosition,
  targetWidth: number,
  size: TooltipSize,
) => {
  const { fromTransform, toTransform, additionalStyles } = placementStyles(
    placement,
    targetWidth,
    size,
  );

  const enterAnimation = keyframes`
    from {
      transform: ${fromTransform} scale(0.01);
      ${additionalStyles};
    }
    to {
      transform: ${toTransform} scale(1);
      ${additionalStyles};
    }
  `;

  const exitAnimation = keyframes`
    from {
      transform: ${toTransform} scale(1);
      ${additionalStyles};
    }
    to {
      transform: ${fromTransform} scale(0.20);
      ${additionalStyles};
    }
  `;

  return { enterAnimation, exitAnimation };
};
