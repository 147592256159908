import {
  ComponentType,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  useContext,
} from 'react';
import {
  LinkProps,
  NavLinkProps,
  Link as ReactRouterLink,
  NavLink as ReactRouterNavLink,
  useNavigate,
} from 'react-router-dom';

import { TrackedLink } from 'components/Link/TrackedLink';
import { UpdateRequiredContext } from 'containers/UpdateRequiredContext';
import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';
import { getUrlWithoutOrigin } from 'utils/url/getUrlWithoutOrigin';
import { isUrlWithProtocol } from 'utils/url/isUrlWithProtocol';

type BaseLinkProps =
  | {
      className?: string;
      component: ComponentType<LinkProps>;
      'data-qa-id'?: string;
      onClick?: MouseEventHandler<HTMLAnchorElement>;
      to: LinkProps['to'];
      state?: LinkProps['state'];
      tabIndex?: number;
      target?: LinkProps['target'];
    }
  | {
      className?: string;
      component: ComponentType<NavLinkProps>;
      'data-qa-id'?: string;
      onClick?: MouseEventHandler<HTMLAnchorElement>;
      to: NavLinkProps['to'];
      state?: NavLinkProps['state'];
      tabIndex?: number;
      target?: LinkProps['target'];
    };

export function BaseLink({
  children,
  className,
  component,
  to,
  onClick,
  state,
  tabIndex,
  target,
  ...props
}: PropsWithChildren<BaseLinkProps>) {
  const Component = component;

  const updateRequired = useContext(UpdateRequiredContext);
  const navigate = useNavigate();

  const deferredOnClick =
    target && target !== '_self'
      ? onClick
      : (event: MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault();
          deferAndStartRouteLoadingAnimation(() => {
            onClick?.(event);

            const normalizedTo =
              typeof to === 'string' && isUrlWithProtocol(to)
                ? getUrlWithoutOrigin(to)
                : to;

            navigate(normalizedTo, { state });
          });
        };

  if (updateRequired && typeof to === 'string' && !state) {
    return (
      <TrackedLink
        className={className}
        data-qa-id={props['data-qa-id']}
        onClick={deferredOnClick}
        href={to}
        eventName="Updating App Version"
        tabIndex={tabIndex}
        target={target}
      >
        {children}
      </TrackedLink>
    );
  }

  return (
    <Component
      className={className}
      data-qa-id={props['data-qa-id']}
      onClick={deferredOnClick}
      to={to}
      state={state}
      tabIndex={tabIndex}
      target={target}
    >
      {children}
    </Component>
  );
}

export function NavLink({
  children,
  className,
  to,
  onClick,
  state,
  tabIndex,
  target,
  ...props
}: PropsWithChildren<NavLinkProps> & { 'data-qa-id'?: string }) {
  return (
    <BaseLink
      className={className as string}
      data-qa-id={props['data-qa-id']}
      component={ReactRouterNavLink}
      to={to}
      onClick={onClick}
      state={state}
      tabIndex={tabIndex}
      target={target}
    >
      {children}
    </BaseLink>
  );
}

export function Link({
  children,
  className,
  to,
  onClick,
  state,
  tabIndex,
  target,
  ...props
}: PropsWithChildren<LinkProps> & { 'data-qa-id'?: string }) {
  return (
    <BaseLink
      data-qa-id={props['data-qa-id']}
      className={className}
      component={ReactRouterLink}
      to={to}
      onClick={onClick}
      state={state}
      tabIndex={tabIndex}
      target={target}
    >
      {children}
    </BaseLink>
  );
}
