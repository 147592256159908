import { MouseEvent, ReactNode } from 'react';

import { ApiUserEnvironmentOrgMini } from 'api/userEnvironment/types/ApiUserEnvironmentOrgMini';
import { DropdownChevron } from 'components/Dropdown/Chevron/DropdownChevron';
import { DropdownHeaderTrigger } from 'components/Dropdown/HeaderTrigger/DropdownHeaderTrigger';
import { FindAJobDropdown } from 'components/FindAJobDropdown/FindAJobDropdown';
import { IdealistLogoOnDemand } from 'components/Logo/IdealistLogoOnDemand';
import { PostOnIdealistDropdown } from 'components/PostOnIdealistDropdown/PostOnIdealistDropdown';
import { TakeActionDropdown } from 'components/TakeActionDropdown/TakeActionDropdown';
import { useIsClient } from 'hooks/useIsClient';
import { useViewportBreakpoint } from 'hooks/viewport/useViewportBreakpoint';
import { homeRoute } from 'routing/routes';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import {
  PageHeaderAuthHolder,
  PageHeaderAuthLink,
  PageHeaderCartPillHolder,
  PageHeaderDesktopDonateLinkHolder,
  PageHeaderDesktopSearchHolder,
  PageHeaderHolder,
  PageHeaderLeft,
  PageHeaderLinksHolder,
  PageHeaderLogoLink,
  PageHeaderMobileDonateButtonHolder,
  PageHeaderMobileDonateButtonOrLink,
  PageHeaderMobileLinksPlaceholder,
  PageHeaderMobileSearchHolder,
  PageHeaderMyAccountHolder,
  PageHeaderRight,
  PageHeaderTopSectionHolder,
} from './PageHeader.styled';

type Props = {
  locale: UserLocale;
  userDisplayName: string | undefined;
  isUserLoaded: boolean;
  orgs: ApiUserEnvironmentOrgMini[];

  loginHref: string;
  onClickLogin: (event: MouseEvent<HTMLAnchorElement>) => void;

  signupHref: string;
  onClickSignup: (event: MouseEvent<HTMLAnchorElement>) => void;

  donate: {
    text: string;
    href: string;
  };

  myAccountDropdown: ReactNode;
  notificationsDropdown: ReactNode;
  cartPill: ReactNode;

  desktopSearch: ReactNode;
  mobileSearchDropdown: ReactNode;

  /*
   * HTML that needs to be sticky alongside the header
   */
  below: ReactNode;
};

export function PageHeader({
  locale,
  userDisplayName,
  isUserLoaded,
  orgs,
  loginHref,
  onClickLogin,
  signupHref,
  onClickSignup,
  donate,
  notificationsDropdown,
  myAccountDropdown,
  cartPill,
  desktopSearch,
  mobileSearchDropdown,
  below,
}: Props) {
  const isLoggedIn = typeof userDisplayName !== 'undefined';

  const isClient = useIsClient();
  const viewport = useViewportBreakpoint();
  const isDesktop = isClient && ['md', 'lg'].includes(viewport);

  return (
    <PageHeaderHolder data-qa-id="page-header">
      <PageHeaderTopSectionHolder>
        <PageHeaderLeft>
          <PageHeaderLogoLink
            to={homeRoute.with({ lang: CURRENT_LOCALE })}
            title="Idealist"
            onClick={() => trackClicked('Idealist Logo Header')}
            data-qa-id="header-logo-link"
          >
            <IdealistLogoOnDemand
              withText
              height={{ mobile: 28, desktop: 36 }}
            />
          </PageHeaderLogoLink>

          <PageHeaderLinksHolder>
            <FindAJobDropdown
              locale={locale}
              renderTrigger={({ triggerText, triggerHref }) => (
                <DropdownHeaderTrigger
                  variant="grey"
                  qaId="page-header-find-a-job-dropdown-trigger"
                  asRouterLink={isDesktop ? { to: triggerHref } : undefined}
                  onClick={() => {
                    if (isDesktop) {
                      trackClicked('Find a Job', { context: 'Header' });
                    }
                  }}
                >
                  {triggerText} <DropdownChevron />
                </DropdownHeaderTrigger>
              )}
              trackClickedContext="Header Dropdown > Find a Job"
            />

            <TakeActionDropdown
              locale={locale}
              renderTrigger={({ triggerText, triggerHref }) => (
                <DropdownHeaderTrigger
                  variant="grey"
                  qaId="page-header-take-action-dropdown-trigger"
                  asRouterLink={isDesktop ? { to: triggerHref } : undefined}
                  onClick={() => {
                    if (isDesktop) {
                      trackClicked('Take Action', { context: 'Header' });
                    }
                  }}
                >
                  {triggerText} <DropdownChevron />
                </DropdownHeaderTrigger>
              )}
              trackClickedContext="Header Dropdown > Take Action"
            />

            <PostOnIdealistDropdown
              locale={locale}
              renderTrigger={({ triggerText, triggerHref }) => (
                <DropdownHeaderTrigger
                  variant="grey"
                  qaId="page-header-post-on-idealist-dropdown-trigger"
                  asRouterLink={isDesktop ? { to: triggerHref } : undefined}
                  onClick={() => {
                    if (isDesktop) {
                      trackClicked('Post a Listing', { context: 'Header' });
                    }
                  }}
                >
                  {triggerText} <DropdownChevron />
                </DropdownHeaderTrigger>
              )}
              trackClickedContext="Header Dropdown > Post on Idealist"
            />

            <PageHeaderDesktopDonateLinkHolder>
              <DropdownHeaderTrigger
                variant="grey"
                asRouterLink={{ to: donate.href }}
                onClick={() => trackClicked('Donate', { context: 'Header' })}
              >
                {donate.text}
              </DropdownHeaderTrigger>
            </PageHeaderDesktopDonateLinkHolder>
          </PageHeaderLinksHolder>
        </PageHeaderLeft>

        <PageHeaderRight>
          {isUserLoaded && !isLoggedIn && (
            <PageHeaderAuthHolder>
              <PageHeaderAuthLink
                href={loginHref}
                onClick={(e) => {
                  trackClicked('Log In Header');
                  onClickLogin(e);
                }}
                data-qa-id="login-link"
                aria-label={getText('Log In')}
              >
                {getText('Log In')}
              </PageHeaderAuthLink>

              <PageHeaderAuthLink
                href={signupHref}
                onClick={(e) => {
                  trackClicked('Signup Header');
                  onClickSignup(e);
                }}
                data-qa-id="signup-link"
                aria-label={getText('Sign Up')}
              >
                {getText('Sign Up')}
              </PageHeaderAuthLink>
            </PageHeaderAuthHolder>
          )}

          <PageHeaderMobileSearchHolder>
            {mobileSearchDropdown}
          </PageHeaderMobileSearchHolder>

          {isLoggedIn && (
            <PageHeaderMyAccountHolder>
              {myAccountDropdown}
            </PageHeaderMyAccountHolder>
          )}

          {isLoggedIn && notificationsDropdown}

          {orgs.length > 0 && (
            <PageHeaderCartPillHolder>{cartPill}</PageHeaderCartPillHolder>
          )}

          <PageHeaderMobileDonateButtonHolder>
            <PageHeaderMobileDonateButtonOrLink
              type="link"
              href={donate.href}
              onClick={() => trackClicked('Donate', { context: 'Header' })}
            >
              {donate.text}
            </PageHeaderMobileDonateButtonOrLink>
          </PageHeaderMobileDonateButtonHolder>
        </PageHeaderRight>
      </PageHeaderTopSectionHolder>

      <PageHeaderMobileLinksPlaceholder />

      {desktopSearch && (
        <PageHeaderDesktopSearchHolder data-qa-id="page-header-search-menu">
          {desktopSearch}
        </PageHeaderDesktopSearchHolder>
      )}

      {below}
    </PageHeaderHolder>
  );
}
