import { ApiUserEnvironment } from 'api/userEnvironment/types/ApiUserEnvironment';
import { del } from 'utils/http/del';
import { getJson } from 'utils/http/getJson';
import { patchJson } from 'utils/http/patchJson';
import { postJson } from 'utils/http/postJson';
import { putJson } from 'utils/http/putJson';

import { ApiApplicantProfile } from './types/ApiApplicantProfile';
import { ApiWorkHistoryJob } from './types/ApiWorkHistoryJob';

export type ApiApplicantProfileResponse = {
  resumeId: string | null;
  isRecruitable: boolean | null;
  applicantProfile: ApiApplicantProfile | null;
  userEnvironment?: ApiUserEnvironment; // Only present when using apiUpdateApplicantProfile
};

// NOTE: This needs to be kept consistent with any default states in the model.
const emptyProfile = {
  resumeId: null,
  isRecruitable: null,
  workHistoryJobs: [],
  skills: [],
  willingToWorkOnsite: true,
  willingToWorkRemote: true,
  willingToWorkHybrid: true,
  willingToWorkPartTime: true,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapProfileResponse(response: any) {
  const {
    resumeS3FileId: resumeId,
    isRecruitable,
    ...profile
  } = response.applicantProfile || emptyProfile;
  return {
    ...response,
    resumeId,
    isRecruitable,
    applicantProfile: profile,
  };
}

export function apiFetchApplicantProfile(): Promise<ApiApplicantProfileResponse> {
  return getJson('/data/userdashboard/applicant-profile/profile').then(
    mapProfileResponse,
  );
}

export function apiUpdateApplicantProfile(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>,
): Promise<
  ApiApplicantProfileResponse & { userEnvironment: ApiUserEnvironment }
> {
  return patchJson(
    '/data/userdashboard/applicant-profile/profile',
    values,
  ).then(mapProfileResponse);
}

export function apiAddWorkHistoryJob(
  job: ApiWorkHistoryJob,
): Promise<ApiApplicantProfileResponse> {
  return postJson(
    '/data/userdashboard/applicant-profile/work-history/jobs',
    job,
  ).then(mapProfileResponse);
}

export function apiEditWorkHistoryJob(
  id: string,
  job: ApiWorkHistoryJob,
): Promise<ApiApplicantProfileResponse> {
  return putJson(
    `/data/userdashboard/applicant-profile/work-history/jobs/${id}`,
    job,
  ).then(mapProfileResponse);
}

export function apiDeleteWorkHistoryJob(
  id: string,
): Promise<ApiApplicantProfileResponse> {
  return del(
    `/data/userdashboard/applicant-profile/work-history/jobs/${id}`,
  ).then(mapProfileResponse);
}

export function apiFetchUserByEmail(email: string) {
  return getJson(
    `/data/users/by-email?email=${encodeURIComponent(email)}`,
  ).then(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    (result) => result && result.userExists,
  );
}
