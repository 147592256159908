import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { SearchInternshipsPageLoadable } from 'modules/search/pages/Internships/SearchInternshipsPageLoadable';
import { AppRouteObject } from 'routing/classes/types/AppRouteObject';
import { loadableLoader } from 'routing/utils/loadableLoader';

import { searchGetInternshipsRoutePath } from './helpers/searchGetInternshipsRoutePath';

const urlByLocale = {
  en: searchGetInternshipsRoutePath({ lang: 'en' }),
  es: searchGetInternshipsRoutePath({ lang: 'es' }),
  pt: searchGetInternshipsRoutePath({ lang: 'pt' }),
} as const satisfies Record<UserLocale, string>;

export const searchInternshipsRouteObjects = (['en', 'es', 'pt'] as const).map(
  (locale) => ({
    path: urlByLocale[locale],
    handle: {
      urlByLocale,
      darkBackground: true,
      defaultSearchType: 'INTERNSHIP',
      hideFooterDivider: true,
      showDesktopSearch: true,
      isSearchPage: true,
    } satisfies LayoutHandleProps,
    loader: loadableLoader(SearchInternshipsPageLoadable),
    element: <SearchInternshipsPageLoadable />,
  }),
) satisfies AppRouteObject[];
