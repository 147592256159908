import { AppRouteGroup } from 'routing/classes/AppRouteGroup';

export const userDashboardRouteGroup = new AppRouteGroup('/dashboard');

export const userDashboardSavedItemsRoute = userDashboardRouteGroup.route(
  'saved-items',
  ['tab', 'page'],
);

export const userDashboardViewEmploymentOnboardingRoute =
  userDashboardRouteGroup.route('employment-profile/:slug');

export const userDashboardProfileRoute =
  userDashboardRouteGroup.route('profile');

// TODO: remove after a while
export const userDashboardOldEmploymentProfileRoute =
  userDashboardRouteGroup.route('employment-profile');

export const userDashboardProfessionalExperienceRoute =
  userDashboardRouteGroup.route('professional-experience');

export const userDashboardNotificationPreferencesRoute =
  userDashboardRouteGroup.route('notification-preferences');

export const userDashboardAccountSettingsRoute =
  userDashboardRouteGroup.route('account-settings');

export const userDashboardPaymentMethodsAddCardRoute =
  userDashboardRouteGroup.route('payment-methods/add-card');

export const userDashboardApplicationHistoryRoute =
  userDashboardRouteGroup.route('application-history');
