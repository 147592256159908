import { SearchLocation } from 'modules/search/types/SearchLocation';

export function extractLocationProperties(location: SearchLocation) {
  return {
    location_geo_type: location.geoType,
    location_latitude: location.latitude,
    location_longitude: location.longitude,
    location_state: location.stateCode,
    location_text: location.text,
  };
}
