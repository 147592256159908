import { PrimaryButton } from 'components/Button';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { scrollToTop } from 'utils/scroll';

export function SearchButton() {
  const { isEnvironmentLoaded } = useUserEnvironment();

  return (
    <PrimaryButton
      icon={{ type: 'normalized', name: 'search' }}
      fullWidth
      type="submit"
      data-qa-id="search-button"
      dataReady={isEnvironmentLoaded}
      onClick={scrollToTop}
    >
      {getText('Search')}
    </PrimaryButton>
  );
}
