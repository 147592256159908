import { ApiAdmin } from 'api/types/ApiAdmin';
import { ApiAdminInvite } from 'api/types/ApiAdminInvite';
import { del } from 'utils/http/del';
import { postJson } from 'utils/http/postJson';

export function addAdmin(
  orgId: string,
  email: string,
): Promise<{
  user: ApiAdmin | null | undefined;
  invite: ApiAdminInvite | null | undefined;
}> {
  return postJson(`/data/orgdashboard/${orgId}/admins`, {
    email,
  });
}

export function removeAdmin(orgId: string, userId: string) {
  return del(`/data/orgdashboard/${orgId}/admins/${userId}`);
}
