import { ApiAdmin } from 'api/types/ApiAdmin';
import { ApiAdminInvite } from 'api/types/ApiAdminInvite';
import { postJson } from 'utils/http/postJson';

export function addOwner(
  orgId: string,
  email: string,
): Promise<{
  user: ApiAdmin | null | undefined;
  invite: ApiAdminInvite | null | undefined;
}> {
  return postJson(`/data/orgdashboard/${orgId}/owners`, {
    email,
  });
}
