import { postJson } from 'utils/http/postJson';

export type TrackedUserEventName =
  | 'JAZZHR_REFERRAL'
  | 'LISTING_REFERRAL'
  | 'CAMPAIGN'
  | 'CONTACT_SUGGESTED_APPLICANT_CLICK';

export const utmSourcesToTrack: Record<string, TrackedUserEventName> = {
  'jazzhr-ref': 'JAZZHR_REFERRAL',
};

export type TrackedUserEventPayload = {
  eventName: TrackedUserEventName;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraData: Record<string, any>;
};

export function trackUserEvent(eventInfo: TrackedUserEventPayload) {
  return postJson('/data/tracked-events', eventInfo);
}
