import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  forwardRef,
} from 'react';

import { ClearButton } from 'components/Input/ClearButton';
import { Input } from 'components/Input/Input';
import {
  InputAffixVariant,
  InputStyleVariant,
} from 'components/Input/Input.types';
import { InputIconAffix } from 'components/Input/affixes/InputIconAffix';
import { InputIconContainer } from 'components/LocationInput/InputIconContainer';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { LocationInputWrapper } from './LocationInputWrapper';

type Props = {
  autoComplete?: string;
  showLocationIcon: boolean;
  onClear: (() => void) | null | undefined;
  className?: string;
  disabled?: boolean;
  gMapsErrored: boolean;
  'data-qa-id': string;
  hasSuccess?: boolean;
  value: string;
  id?: string;
  placeholder?: string;
  required?: boolean;
  title?: string;
  'aria-autocomplete'?: 'none' | 'inline' | 'list' | 'both' | undefined;
  'aria-controls'?: string;
  'aria-activedescendant'?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  affixVariant?: InputAffixVariant;
  styleVariant?: InputStyleVariant;
};

export const StyledInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      autoComplete,
      showLocationIcon,
      onClear,
      className,
      disabled,
      gMapsErrored,
      id,
      onChange,
      onBlur,
      onKeyDown,
      onKeyPress,
      onFocus,
      placeholder,
      required,
      affixVariant,
      styleVariant,
      title,
      value,
      ...inputProps
    }: Props,
    ref,
  ) => (
    <Tooltip
      closeable
      variant="brand-blue"
      placement="bottom-center"
      size="large"
      renderCondition={gMapsErrored}
      content={getText(
        'Our geolocation provider (Google Maps) did not load correctly. Please reload the page.',
      )}
    >
      <LocationInputWrapper className={className}>
        <Input
          fullHeight
          fullWidth
          inputProps={{
            ref,
            qaId: inputProps['data-qa-id'],
            disabled,
            value,
            autoComplete,
            ariaAutocomplete: inputProps['aria-autocomplete'],
            ariaControls: inputProps['aria-controls'],
            ariaActiveDescendant: inputProps['aria-activedescendant'],
            id,
            placeholder,
            required,
            title,
            onChange,
            onBlur,
            onFocus,
            onKeyDown,
            onKeyPress,
          }}
          prefix={
            showLocationIcon ? (
              <InputIconContainer>
                <InputIconAffix
                  overrideIconColor="black"
                  iconName="location"
                  size={24}
                  noPaddingRight
                />
              </InputIconContainer>
            ) : null
          }
          affixVariant={affixVariant}
          styleVariant={styleVariant}
          suffix={
            onClear ? (
              <ClearButton
                ariaLabel={getText('Clear location')}
                onClear={onClear}
                qaId={`${inputProps['data-qa-id']}-clear`}
              />
            ) : null
          }
          hideSuffixWhenEmpty
        />
      </LocationInputWrapper>
    </Tooltip>
  ),
);
