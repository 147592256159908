import { ComponentProps } from 'react';

import { Button } from 'components/Button/Button';

type Props = ComponentProps<typeof Button>;

export function PrimaryButton({ ...props }: Props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button variant="primary" {...props} />;
}
