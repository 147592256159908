import { useRef } from 'react';

/**
 * Different from `useRef` that takes `initialValue`, this hook immediately updates
 * the value of the ref.
 *
 * This hook is used to avoid triggering a change `useCallback` or `useEffect`
 * while also being able to consume the up-to-date value.
 */
export function useValueRef<TValue>(value: TValue) {
  const ref = useRef(value);
  ref.current = value;

  return ref;
}
