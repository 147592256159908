import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { useLoadBundleCssOnce } from 'zustand-stores/loadCssBundleStore';

/**
 * Only load style.css when the user is logged in.
 *
 * This file includes CSS that only needs to be used by logged-in users.
 */
export function LoggedInStylesLoader() {
  const { user } = useUserEnvironment();
  useLoadBundleCssOnce(user ? 'loggedInStyle' : undefined);
  return null;
}
