import { ApiUserLocation } from 'api/types/ApiUserLocation';

// Types

export type MainStoreUserLocationState = ApiUserLocation & {
  isFetching?: boolean;
  isLoaded?: boolean;
};

export type FetchUserLocationRequestAction = {
  type: 'USER_LOCATION_FETCH_REQUEST';
};

export type FetchUserLocationSuccessAction = {
  type: 'USER_LOCATION_FETCH_SUCCESS';
  userLocation: ApiUserLocation;
};

export type MainStoreUserLocationAction =
  | FetchUserLocationRequestAction
  | FetchUserLocationSuccessAction;

// Reducer

export function userLocationReducer(
  // TODO: Fix this the next time the file is edited.
  // @ts-expect-error TS(2322): Type '{}' is not assignable to type 'MainStoreUser... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MainStoreUserLocationState = {},
  action: MainStoreUserLocationAction,
) {
  switch (action.type) {
    case 'USER_LOCATION_FETCH_REQUEST':
      return { ...state, isFetching: true };

    case 'USER_LOCATION_FETCH_SUCCESS':
      return { ...action.userLocation, isFetching: false, isLoaded: true };

    default:
      return state;
  }
}
