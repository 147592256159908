import queryString from 'query-string';

import { getDefaultSearchRadius } from 'utils/constants/general/defaultSearchRadius';
import { SEARCH_RADIUSES } from 'utils/constants/general/searchRadiuses';

const queryParams =
  typeof window === 'object' ? queryString.parse(window.location.search) : {};

// Types

export type SetSearchRadiusAction = {
  type: 'SEARCH_RADIUS_SET';
  radius: string;
  didUserChange: boolean;
};

export type MainStoreSearchRadiusAction = SetSearchRadiusAction;

// Reducer

const parseRadius = ({
  radius,
  sort,
}: {
  radius?: string | null | undefined;
  sort?: string;
}) => {
  if (
    radius &&
    SEARCH_RADIUSES[CURRENT_LOCALE].find((option) => option.id === radius)
  ) {
    return radius;
  }

  return getDefaultSearchRadius(sort);
};

export type MainStoreSearchRadiusState = {
  value: string;
  userHasChanged: boolean; // has the user ever changed the radius manually?
};

export function searchRadiusReducer(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MainStoreSearchRadiusState = {
    value: parseRadius(queryParams),
    userHasChanged: false,
  },
  action: MainStoreSearchRadiusAction,
) {
  switch (action.type) {
    case 'SEARCH_RADIUS_SET': {
      return {
        ...state,
        value: action.radius,
        // userHasChanged if the user changed this value
        // in the past or in the current action
        userHasChanged: state.userHasChanged || action.didUserChange,
      };
    }

    default:
      return state;
  }
}
