import { Dispatch } from '@reduxjs/toolkit';

import { apiSetABExperimentBucket } from 'api/abExperiment/apiSetABExperimentBucket';
import {
  ApiABExperimentBucketForId,
  ApiABExperimentId,
} from 'api/abExperiment/types/ApiABExperiment';
import {
  ApiApplicantProfileResponse,
  apiAddWorkHistoryJob,
  apiDeleteWorkHistoryJob,
  apiEditWorkHistoryJob,
  apiFetchApplicantProfile,
  apiUpdateApplicantProfile,
} from 'api/applicantProfile/apiApplicantProfile';
import { ApiWorkHistoryJob } from 'api/applicantProfile/types/ApiWorkHistoryJob';
import {
  apiCreateSavedListing,
  apiDeleteSavedListing,
} from 'api/savedListing/apiSavedListing';
import {
  apiEditSavedSearch,
  apiCreateSavedSearch as apiSaveSearch,
  apiDeleteSavedSearch as apiUnsaveSearch,
} from 'api/savedSearch/apiUserEnvironmentSavedSearch';
import { ApiSavedSearchUnsaved } from 'api/savedSearch/types/ApiSavedSearch';
import { ApiSavedSearchId } from 'api/types/ApiTypedId';
import { fetchUserEnvironment as apiFetchUserEnvironment } from 'api/userEnvironment/apiUserEnvironment';
import {
  ApiEnvironment,
  ApiUser,
  ApiUserEnvironment,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { searchGetInitialLocation } from 'modules/search/helpers/searchGetInitialLocation';
import { updateSearchLocation } from 'store/mainSearch/mainSearch.actions';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { pushFlashMessage } from 'zustand-stores/flashMessagesStore';

import {
  FetchUserEnvironmentRequestAction,
  FetchUserEnvironmentSuccessAction,
  MainStoreUserEnvironmentAction,
  MainStoreUserEnvironmentState,
  UpdateUserEnvironmentEnvironmentAction,
  UpdateUserEnvironmentUserAction,
} from './userEnvironment';

const fetchUserEnvironmentRequest = (): FetchUserEnvironmentRequestAction => ({
  type: 'USER_ENVIRONMENT_FETCH_REQUEST',
});

const fetchUserEnvironmentSuccess = (
  userEnvironment: ApiUserEnvironment,
): FetchUserEnvironmentSuccessAction => ({
  type: 'USER_ENVIRONMENT_FETCH_SUCCESS',
  userEnvironment,
});

export const updateUserEnvironmentUser = (
  user: ApiUser,
): UpdateUserEnvironmentUserAction => ({
  type: 'UPDATE_USER_ENVIRONMENT_USER',
  user,
});

export const updateUserEnvironmentEnvironment = (
  environment: ApiEnvironment,
): UpdateUserEnvironmentEnvironmentAction => ({
  type: 'UPDATE_USER_ENVIRONMENT_ENVIRONMENT',
  environment,
});

export const fetchUserEnvironment =
  () =>
  (
    dispatch: Dispatch<MainStoreUserEnvironmentAction>,
    getState: () => { userEnvironment: MainStoreUserEnvironmentState },
  ): void => {
    const { userEnvironment } = getState();

    if (userEnvironment.isEnvironmentFetching) {
      return;
    }

    dispatch(fetchUserEnvironmentRequest());
    apiFetchUserEnvironment().then((fetchedUserEnvironment) => {
      // Read searchLocation from localstorage
      // @TODO replace fallback to searchLocation with guessedLocation
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch<any>(
        updateSearchLocation(
          searchGetInitialLocation(
            fetchedUserEnvironment.environment.guessedLocation,
          ),
          false,
        ),
      );
      dispatch(fetchUserEnvironmentSuccess(fetchedUserEnvironment));
    });
  };

export const updateUserEnvironment = fetchUserEnvironmentSuccess;

export const updateUser =
  (values: Omit<MainStoreUserEnvironmentState['user'], 'id'>) =>
  (
    dispatch: Dispatch<MainStoreUserEnvironmentAction>,
    getState: () => { userEnvironment: MainStoreUserEnvironmentState },
  ) => {
    const { userEnvironment } = getState();
    const { environment, user } = userEnvironment;

    if (!user || !environment) return Promise.resolve();

    return Promise.resolve().then(() =>
      dispatch(
        fetchUserEnvironmentSuccess({
          user: { ...user, ...values },
          environment,
        }),
      ),
    );
  };

export const saveListing =
  (listingId: string, listingType: string) =>
  (dispatch: Dispatch<MainStoreUserEnvironmentAction>) => {
    dispatch({
      type: 'SAVE_LISTING',
      listingId,
      listingType,
    });

    return apiCreateSavedListing(listingId, listingType).catch((error) => {
      dispatch({
        type: 'UNSAVE_LISTING',
        listingId,
        listingType,
      });
      throw error;
    });
  };

export const unsaveListing =
  (listingId: string, listingType: string) =>
  (dispatch: Dispatch<MainStoreUserEnvironmentAction>) => {
    dispatch({
      type: 'UNSAVE_LISTING',
      listingId,
    });

    return apiDeleteSavedListing(listingId, listingType).catch((error) => {
      // if listing was already removed, ignore error
      if (error.statusCode !== 404) {
        dispatch({
          type: 'SAVE_LISTING',
          listingId,
          listingType,
        });
        throw error;
      }
    });
  };

export const saveSearch =
  (search: ApiSavedSearchUnsaved) =>
  (dispatch: Dispatch<MainStoreUserEnvironmentAction>) =>
    apiSaveSearch(search).then((savedSearch) => {
      dispatch({
        type: 'SAVE_SEARCH',
        savedSearch,
      });
      dispatch({
        type: 'UPDATE_SAVING_SEARCH_STATUS',
        savingSearchStatus: 'EDITING',
      });
      pushFlashMessage({ type: 'SAVED_SEARCH' });
      trackEvent('Created Email Alert');
    });

type ApiUpdateSavedSearchPayload = {
  id: string;
  name: string;
  notifyByEmail: boolean;
};

export const editSavedSearch =
  (postId: string, search: ApiUpdateSavedSearchPayload) =>
  (dispatch: Dispatch<MainStoreUserEnvironmentAction>) =>
    apiEditSavedSearch(postId, search).then((editedSavedSearch) => {
      dispatch({
        type: 'EDIT_SAVED_SEARCH',
        editedSavedSearch,
      });
      dispatch({
        type: 'UPDATE_SAVING_SEARCH_STATUS',
        savingSearchStatus: 'SAVED',
      });
    });

export const updateSavingSearchStatus =
  (savingSearchStatus: 'UNSAVED' | 'EDITING' | 'SAVED') =>
  (dispatch: Dispatch<MainStoreUserEnvironmentAction>) =>
    dispatch({
      type: 'UPDATE_SAVING_SEARCH_STATUS',
      savingSearchStatus,
    });

export const unsaveSearch =
  (searchId: ApiSavedSearchId) =>
  (dispatch: Dispatch<MainStoreUserEnvironmentAction>) =>
    apiUnsaveSearch(searchId).then(() => {
      dispatch({
        type: 'UNSAVE_SEARCH',
        searchId,
      });
      dispatch({
        type: 'UPDATE_SAVING_SEARCH_STATUS',
        savingSearchStatus: 'UNSAVED',
      });
      pushFlashMessage({ type: 'UNSAVED_SEARCH' });
    });

export function setABExperimentBucket<TExperimentId extends ApiABExperimentId>(
  experimentId: TExperimentId,
  bucket: ApiABExperimentBucketForId<TExperimentId>,
) {
  return (
    dispatch: Dispatch<MainStoreUserEnvironmentAction>,
    getState: () => { userEnvironment: MainStoreUserEnvironmentState },
  ) => {
    const { userEnvironment } = getState();
    return apiSetABExperimentBucket(experimentId, bucket).then(
      (abExperiments) => {
        dispatch(
          updateUserEnvironment({
            ...userEnvironment,
            environment: { ...userEnvironment.environment, abExperiments },
          } as ApiUserEnvironment),
        );
        return abExperiments;
      },
    );
  };
}

function dispatchProfileUpdate(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: (...args: Array<any>) => Promise<ApiApplicantProfileResponse>,
  dispatch: Dispatch<MainStoreUserEnvironmentAction>,
) {
  dispatch({
    type: 'APPLICANT_PROFILE_FETCH_REQUEST',
  });
  return request()
    .then((applicantProfileResponse) => {
      dispatch({
        type: 'APPLICANT_PROFILE_FETCH_SUCCESS',
        applicantProfileResponse,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'APPLICANT_PROFILE_FETCH_FAILURE',
      });
      throw error;
    });
}

export function fetchApplicantProfile() {
  return (dispatch: Dispatch<MainStoreUserEnvironmentAction>) =>
    dispatchProfileUpdate(() => apiFetchApplicantProfile(), dispatch);
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateApplicantProfile(values: Record<string, any>) {
  return (dispatch: Dispatch) =>
    dispatchProfileUpdate(() => apiUpdateApplicantProfile(values), dispatch);
}

export function updateWorkHistoryJob(
  job: ApiWorkHistoryJob,
  id: string | null | undefined,
) {
  return (dispatch: Dispatch) => {
    const submit = () =>
      id ? apiEditWorkHistoryJob(id, job) : apiAddWorkHistoryJob(job);

    return dispatchProfileUpdate(() => submit(), dispatch);
  };
}

export function deleteWorkHistoryJob(id: string) {
  return (dispatch: Dispatch) =>
    dispatchProfileUpdate(() => apiDeleteWorkHistoryJob(id), dispatch);
}
