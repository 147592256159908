import { useMemo } from 'react';

import { LocalDropdownNotificationType } from 'types/LocalNotification/LocalDropdownNotificationType';
import { LocalNotificationData } from 'types/LocalNotification/LocalNotificationData';
import { LocalNotificationStatus } from 'types/LocalNotification/LocalNotificationStatus';
import { LocalNotificationType } from 'types/LocalNotification/LocalNotificationType';
import { LocalProductNotificationType } from 'types/LocalNotification/LocalProductNotificationType';
import { MappedLocalNotification } from 'types/LocalNotification/MappedLocalNotification';

import { createLocalStorageStore } from './utils/createLocalStorageStore';

const IDEALIST_NOTIFICATIONS_KEY = 'idealistNotifications';

type LocalNotificationsStoreData = {
  [k in LocalNotificationType]?: LocalNotificationData;
};

const { getValue, useValue, setValue } =
  createLocalStorageStore<LocalNotificationsStoreData>({
    key: IDEALIST_NOTIFICATIONS_KEY,
    getInitializiationValue: (currentNotifications) => {
      const newNotifications: Record<string, LocalNotificationData> = {
        ...(currentNotifications || {}),
      };

      // Automatically create notifications
      [
        ...Object.values(LocalDropdownNotificationType),
        ...Object.values(LocalProductNotificationType),
      ].forEach((type) => {
        if (newNotifications[type]) return;

        newNotifications[type] = {
          status: LocalNotificationStatus.NEW,
          viewed: false,
          read: false,
        } satisfies LocalNotificationData;
      });

      return newNotifications;
    },
  });

// Actions

export function updateLocalNotification(
  type: LocalNotificationType,
  partialValue: Partial<MappedLocalNotification>,
) {
  const currentNotifications = getValue() || {};
  const currentNotification = currentNotifications?.[type] || {};

  return setValue({
    ...currentNotifications,
    [type]: { ...currentNotification, ...partialValue },
  });
}

export function dismissLocalNotification(type: LocalNotificationType) {
  updateLocalNotification(type, { status: LocalNotificationStatus.DISMISSED });
}

export function markLocalNotificationsAsViewed(types: LocalNotificationType[]) {
  const currentValue = getValue();
  if (!currentValue) return;

  const newValue: LocalNotificationsStoreData = { ...currentValue };

  (Object.keys(newValue) as LocalNotificationType[]).forEach((type) => {
    const valueForType = newValue[type];
    if (!valueForType || !types.includes(type)) return;

    newValue[type] = { ...valueForType, viewed: true };
  });

  setValue(newValue);
}

// Hooks

export function useLocalNotification(
  type: LocalNotificationType,
): LocalNotificationData | undefined {
  const localNotifications = useValue();
  return localNotifications?.[type];
}

/**
 * Transforms data stored as:
 * {
 *   "NOTIFICATION_TYPE": {
 *     status: "NEW"
 *   }
 * }
 *
 * to this format:
 * [
 *   {
 *     type: "NOTIFICATION_TYPE",
 *     status: "NEW"
 *   }
 * ]
 */
export function useMappedLocalNotifications() {
  const localNotifications = useValue();

  return useMemo(
    () =>
      (Object.keys(localNotifications || {}) as LocalNotificationType[]).map(
        (type) =>
          ({ type, ...localNotifications?.[type] }) as MappedLocalNotification,
      ),
    [localNotifications],
  );
}
