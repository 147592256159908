import { ApiApplicantProfile } from 'api/applicantProfile/types/ApiApplicantProfile';
import { MainStoreUserEnvironmentState } from 'store/ducks/userEnvironment';
import { getItem } from 'utils/localStorage';
import { isNonAdmin } from 'utils/userEnvironment';

export function hasResumeDb(
  userEnvironment: MainStoreUserEnvironmentState,
): boolean {
  return Boolean(
    getItem('enableResumeDb') !== false &&
      (userEnvironment.environment?.isUS ||
        userEnvironment.user?.isRecruitable) &&
      CURRENT_LOCALE === 'en',
  );
}

export function hasWorkHistoryJobs(profile?: ApiApplicantProfile): boolean {
  return Boolean(
    profile?.workHistoryJobs && profile.workHistoryJobs.length > 0,
  );
}

export function promoteResumeDatabase({
  userEnvironment,
  allowLoggedOut,
}: {
  userEnvironment: MainStoreUserEnvironmentState;
  allowLoggedOut?: boolean;
}) {
  const { isUserLoaded, isEnvironmentLoaded, user } = userEnvironment;

  if (!isUserLoaded || !isEnvironmentLoaded) {
    return false;
  }

  if (!hasResumeDb(userEnvironment)) {
    return false;
  }

  if (user && !isNonAdmin(user)) {
    return false;
  }

  if (!user && !allowLoggedOut) {
    return false;
  }

  if (user?.isRecruitable === false || (user?.isRecruitable && user.resumeId)) {
    return false;
  }

  return true;
}
