import { postJson } from 'utils/http/postJson';

export function approveAdminRequest(orgId: string, requestId: string) {
  return postJson(`/data/orgdashboard/${orgId}/admin-requests/${requestId}`, {
    approved: true,
    // @ts-expect-error TS(2345): Argument of type '({ admin }: { admin: any; }) => ... Remove this comment to see the full error message
  }).then(({ admin }) => admin);
}

export function rejectAdminRequest(orgId: string, requestId: string) {
  return postJson(`/data/orgdashboard/${orgId}/admin-requests/${requestId}`, {
    approved: false,
  });
}
