import { create } from 'zustand';

import { onClientHydrated } from './renderingStateStore';

const ssrApiDataStore = create<{ data: Record<string, unknown> }>(() => ({
  data: {},
}));

// Actions

export function setSsrApiData(data: Record<string, unknown>) {
  return ssrApiDataStore.setState({ data });
}

// Hooks

export function getSsrApiDataForUrl<TData>(
  url: string | undefined,
): TData | undefined {
  if (!url) return;

  return ssrApiDataStore.getState().data[url] as TData | undefined;
}

// Event listeners

onClientHydrated(() => {
  // Erase data to avoid caching between page changes
  setSsrApiData({});
});
