import queryString from 'query-string';
import { MouseEvent, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { showModal } from 'zustand-stores/modalStore';

export function useLogin() {
  const location = useLocation();
  const redirectTo = location ? `${location.pathname}${location.search}` : '';
  const redirectParam = queryString.stringify({
    to: redirectTo,
  });

  const onClickLogin = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      showModal('LOGIN', {
        redirectTo,
        manualLogin: true,
      });
    },
    [redirectTo],
  );

  const loginLink = `/login?${redirectParam}`;

  return { onClickLogin, loginLink };
}
