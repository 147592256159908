import {
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useAnimation } from 'hooks/useAnimation';
import { ClickOutside } from 'utils/ClickOutside';

import { DefaultTooltipButton } from './DefaultTooltipButton';
import {
  TargetContainer,
  TooltipCloseButton,
  TooltipContainer,
  TooltipWrapper,
} from './Tooltip.styled';
import { TooltipProvider } from './TooltipContext';
import { TooltipPosition } from './TooltipPosition';
import { TooltipSize } from './TooltipSize';
import { TooltipVariant } from './TooltipVariant';

type Props = {
  animate?: boolean;
  children?: ReactNode;
  closeable?: boolean;
  content: ReactNode;
  className?: string;
  onClose?: () => void;
  placement?: TooltipPosition;
  tooltipWidth?: number;
  renderCondition?: boolean;
  triggerCondition?: boolean;
  size?: TooltipSize;
  variant?: TooltipVariant;
  withArrow?: boolean;
  onRender?: () => void;
};

export const Tooltip = forwardRef(
  (
    {
      animate = false,
      placement = 'top-center',
      children,
      closeable,
      onClose,
      content,
      className,
      tooltipWidth,
      renderCondition,
      triggerCondition = true,
      size = 'small',
      variant = 'brand-blue',
      withArrow = true,
      onRender,
    }: Props,
    ref,
  ) => {
    const [currentPlacement, setCurrentPlacement] = useState(placement);
    const [showTooltip, setShowTooltip] = useState(renderCondition || false);
    const [isTooltipHovered, setIsTooltipHovered] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const targetRef = useRef<HTMLDivElement>(null);
    const hideTooltipTimeout = useRef<number | null>(null);
    const [targetWidth, setTargetWidth] = useState<number>(0);
    const [targetHeight, setTargetHeight] = useState<number>(0);

    const { stage, shouldRender } = useAnimation(
      showTooltip,
      animate ? 200 : 0,
    );

    const handleShowTooltip = () => {
      if (isClosing) {
        setIsClosing(false);
      }

      if (hideTooltipTimeout.current) {
        clearTimeout(hideTooltipTimeout.current);
      }
      setShowTooltip(true);
    };

    const hideTooltipWithDelay = () => {
      if (!isTooltipHovered) {
        hideTooltipTimeout.current = setTimeout(
          () => {
            setShowTooltip(false);
          },
          animate ? 200 : 0,
        ) as unknown as number; // Without casting, returns NodeJS.Timeout
      }
    };

    const handleTriggerMouseOver = () => {
      if ('ontouchstart' in window) {
        return;
      }

      if (triggerCondition) {
        handleShowTooltip();
      }
    };

    const handleTouchEnd = () => {
      if (renderCondition === undefined) {
        setShowTooltip(!showTooltip);
      }
    };

    const handleTriggerMouseLeave = () => hideTooltipWithDelay();

    const handleTooltipMouseOver = () => {
      setIsTooltipHovered(true);
      handleShowTooltip();
    };

    const handleTooltipMouseLeave = () => {
      setIsTooltipHovered(false);
      if (!renderCondition) {
        setShowTooltip(false);
        hideTooltipWithDelay();
      }
    };

    const closeTooltip = useCallback(() => {
      setIsClosing(true);
      setShowTooltip(false);
      if (onClose) onClose();
    }, [onClose]);

    useImperativeHandle(ref, () => ({
      openTooltip: handleShowTooltip,
      closeTooltip,
    }));

    useEffect(() => {
      setShowTooltip(renderCondition || false);
    }, [renderCondition]);

    useEffect(
      () => () => {
        if (hideTooltipTimeout.current) {
          clearTimeout(hideTooltipTimeout.current);
        }
      },
      [],
    );

    useEffect(() => {
      if (
        !targetWidth &&
        !targetHeight &&
        targetRef.current?.firstElementChild
      ) {
        const { width, height } =
          targetRef.current.firstElementChild.getBoundingClientRect();

        setTargetWidth(width);
        setTargetHeight(height);
      }
    }, [targetWidth, targetHeight, showTooltip, targetRef]);

    const resizeHandler = useCallback(() => {
      if (targetRef.current?.firstElementChild) {
        const { bottom, left, right, top } =
          targetRef.current.firstElementChild.getBoundingClientRect();
        let newPlacement: TooltipPosition | null = null;

        // @NOTE: Would be nice to find a way to use the real tooltipMinimumWidth
        // width and height more directly, but we don't always have access to it
        // since it is hidden, so we use the target and infer the width/height
        // as best we can
        const tooltipMinimumHeight = 120;
        const tooltipMinimumWidth = tooltipWidth || size === 'large' ? 300 : 50;

        const arrowSize = size === 'large' ? 20 : 10;
        const arrowHorizontalOffset = targetWidth < arrowSize ? 20 : 0;

        if (
          left - tooltipMinimumWidth + (arrowSize + arrowHorizontalOffset) <
          0
        ) {
          newPlacement = currentPlacement.includes('left')
            ? (currentPlacement.replace('left', 'right') as TooltipPosition)
            : currentPlacement;
        } else if (right + tooltipMinimumWidth > window.innerWidth) {
          newPlacement = currentPlacement.includes('right')
            ? (currentPlacement.replace('right', 'left') as TooltipPosition)
            : currentPlacement;
        } else if (bottom + tooltipMinimumHeight > window.innerHeight) {
          newPlacement = currentPlacement.includes('bottom')
            ? (currentPlacement.replace('bottom', 'top') as TooltipPosition)
            : currentPlacement;
        } else if (top - tooltipMinimumHeight < 0) {
          newPlacement = currentPlacement.includes('top')
            ? (currentPlacement.replace('top', 'bottom') as TooltipPosition)
            : currentPlacement;
        }

        // Update the placement if needed
        if (newPlacement && newPlacement !== currentPlacement) {
          setCurrentPlacement(newPlacement);
        } else if (!newPlacement && currentPlacement !== placement) {
          // Revert to the initial placement if conditions no longer apply
          setCurrentPlacement(placement);
        }
      }
    }, [currentPlacement, placement, size, targetWidth, tooltipWidth]);

    useEffect(() => {
      if (typeof window !== 'undefined') {
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
        return () => {
          window.removeEventListener('resize', resizeHandler);
        };
      }
    }, [resizeHandler]);

    useEffect(() => {
      if (showTooltip && onRender) onRender();
    }, [showTooltip, onRender]);

    const childrenToClone = isValidElement(children) ? (
      children
    ) : (
      <DefaultTooltipButton type="button" />
    );

    return (
      <ClickOutside onClickOutside={closeTooltip}>
        <TooltipProvider value={{ closeTooltip }}>
          <TargetContainer ref={targetRef}>
            {cloneElement(childrenToClone, {
              onTouchEnd: childrenToClone.props.onTouchEnd
                ? childrenToClone.props.onTouchEnd
                : handleTouchEnd,
              onMouseOver:
                renderCondition === undefined
                  ? handleTriggerMouseOver
                  : undefined,
              onMouseOut: closeable ? undefined : handleTriggerMouseLeave,
            })}
            {shouldRender ? (
              <TooltipContainer
                className={className}
                onMouseOver={!isClosing ? handleTooltipMouseOver : undefined}
                onMouseLeave={handleTooltipMouseLeave}
                $animate={animate}
                $targetWidth={targetWidth}
                $targetHeight={targetHeight}
                $placement={currentPlacement}
                $variant={variant}
                $size={size}
                $tooltipWidth={tooltipWidth}
                $withArrow={withArrow}
                $stage={stage}
              >
                <TooltipWrapper $size={size}>
                  {closeable ? (
                    <TooltipCloseButton
                      onClick={closeTooltip}
                      data-qa-id="close-modal-button"
                      data-modal-focus="false"
                    />
                  ) : null}
                  {content}
                </TooltipWrapper>
              </TooltipContainer>
            ) : null}
          </TargetContainer>
        </TooltipProvider>
      </ClickOutside>
    );
  },
);
