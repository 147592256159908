import { ComponentType, ForwardedRef } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-namespace
import * as ss from 'styled-system';

import { getClassName } from 'theme/theme';

type ComponentProps = {
  className?: string;
};

type Props = {
  component?: ComponentType<ComponentProps>;
  forwardedRef?: ForwardedRef<unknown>;
  classes?: Array<UtilityClass>;
  className?: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: (...args: Array<any>) => any;
  position: string;
  top: string;
  right: string;
  bottom: string;
  left: string;
  zIndex: string;
  transform: string;
  m: string;
  margin: string;
  mr: string;
  marginRight: string;
  ml: string;
  marginLeft: string;
  mt: string;
  marginTop: string;
  mb: string;
  marginBottom: string;
  mx: string;
  my: string;
  p: string;
  padding: string;
  pr: string;
  paddingRight: string;
  pl: string;
  paddingLeft: string;
  pt: string;
  paddingTop: string;
  pb: string;
  paddingBottom: string;
  px: string;
  py: string;
  display: string;
  width: string;
  height: string;
  maxWidth: string;
  maxHeight: string;
  minWidth: string;
  minHeight: string;
  flex: string;
  order: string;
  alignSelf: string;
  justifySelf: string;
  alignItems: string;
  alignContent: string;
  justifyContent: string;
  flexBasis: string;
  flexDirection: string;
  flexGrow: string;
  flexShrink: string;
  flexWrap: string;
  columnStart: string;
  columnEnd: string;
  overflow: string;
  color: string;
  opacity: string;
  visibility: string;
  bg: string;
  background: string;
  backgroundImage: string;
  backgroundSize: string;
  backgroundPosition: string;
  backgroundRepeat: string;
  backgroundColor: string;
  borderBottom: string;
  borderTop: string;
  borderLeft: string;
  borderRight: string;
  border: string;
  borderColor: string;
  borderWidth: string;
  borderStyle: string;
  borderRadius: string;
  boxShadow: string;
  cursor: string;
  fontSize: string;
  fontFamily: string;
  gridGap: string;
  gridAutoFlow: string;
  gridArea: string;
  gridTemplateAreas: string;
  gridColumn: string;
  gridColumnGap: string;
  gridAutoColumns: string;
  gridTemplateColumns: string;
  gridRow: string;
  gridRowGap: string;
  gridAutoRows: string;
  gridTemplateRows: string;
  lineHeight: string;
  fontWeight: string;
  letterSpacing: string;
  textAlign: string;
  verticalAlign: string;
  textOverflow: string;
  textTransform: string;
  textDecoration: string;
  whiteSpace: string;
  wordBreak: string;
  transition: string;
  willChange: string;
  pointerEvents: string;
};

export const BaseBox = styled(
  ({
    forwardedRef,
    dispatch,
    position,
    top,
    right,
    bottom,
    left,
    zIndex,
    transform,
    m,
    margin,
    mr,
    marginRight,
    ml,
    marginLeft,
    mt,
    marginTop,
    mb,
    marginBottom,
    mx,
    my,
    p,
    padding,
    pr,
    paddingRight,
    pl,
    paddingLeft,
    pt,
    paddingTop,
    pb,
    paddingBottom,
    px,
    py,
    display,
    width,
    height,
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    flex,
    order,
    alignSelf,
    justifySelf,
    alignItems,
    alignContent,
    justifyContent,
    flexBasis,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    gridGap,
    gridAutoFlow,
    gridArea,
    gridTemplateAreas,
    gridColumn,
    gridColumnGap,
    gridAutoColumns,
    gridTemplateColumns,
    gridRow,
    gridRowGap,
    gridAutoRows,
    gridTemplateRows,
    columnStart,
    columnEnd,
    overflow,
    color,
    opacity,
    visibility,
    bg,
    background,
    backgroundImage,
    backgroundSize,
    backgroundPosition,
    backgroundRepeat,
    backgroundColor,
    borderBottom,
    borderTop,
    borderLeft,
    borderRight,
    border,
    borderColor,
    borderWidth,
    borderStyle,
    borderRadius,
    boxShadow,
    cursor,
    fontSize,
    fontFamily,
    lineHeight,
    fontWeight,
    letterSpacing,
    textAlign,
    verticalAlign,
    textOverflow,
    textTransform,
    textDecoration,
    whiteSpace,
    wordBreak,
    transition,
    willChange,
    pointerEvents,
    component,
    classes,
    className,
    ...props
  }: Props) => {
    const BoxComponent = component || 'div';
    return (
      <BoxComponent
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        // @ts-expect-error fix types
        ref={forwardedRef}
        className={getClassName(className, classes)}
      />
    );
  },
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
).attrs<any>(
  ({
    cursor,
    transition,
    zIndex,
    justifySelf,
    alignSelf,
    alignContent,
    filter,
    opacity,
    background,
    backgroundImage,
    backgroundRepeat,
    backgroundColor,
    backgroundPosition,
    backgroundSize,
    textOverflow,
    textTransform,
    textDecoration,
    whiteSpace,
    willChange,
    wordBreak,
    visibility,
    verticalAlign,
    pointerEvents,
    letterSpacing,
    lineHeight,
  }) => ({
    style: {
      cursor,
      transition,
      zIndex,
      justifySelf,
      alignSelf,
      alignContent,
      filter,
      opacity,
      background,
      backgroundImage,
      backgroundRepeat,
      backgroundColor,
      backgroundPosition,
      backgroundSize,
      textOverflow,
      textTransform,
      textDecoration,
      whiteSpace,
      willChange,
      wordBreak,
      visibility,
      verticalAlign,
      pointerEvents,
      letterSpacing,
      lineHeight,
    },
  }),
)<{ $printHidden: boolean }>`
  ${ss.position}
  ${ss.top}
  ${ss.right}
  ${ss.bottom}
  ${ss.left}
  ${ss.space}
  ${ss.display}
  ${ss.width}
  ${ss.height}
  ${ss.maxWidth}
  ${ss.maxHeight}
  ${ss.minWidth}
  ${ss.minHeight}
  ${ss.flex}
  ${ss.order}
  ${ss.alignItems}
  ${ss.justifyContent}
  ${ss.flexBasis}
  ${ss.flexWrap}
  ${ss.flexDirection}
  ${ss.flexGrow}
  ${ss.flexShrink}
  ${ss.gridGap}
  ${ss.gridAutoFlow}
  ${ss.gridArea}
  ${ss.gridTemplateAreas}
  ${ss.gridColumn}
  ${ss.gridColumnGap}
  ${ss.gridAutoColumns}
  ${ss.gridTemplateColumns}
  ${ss.gridRow}
  ${ss.gridRowGap}
  ${ss.gridAutoRows}
  ${ss.gridTemplateRows}
  ${ss.color}
  ${ss.borders}
  ${ss.borderRadius}
  ${ss.boxShadow}
  ${ss.fontSize}
  ${ss.fontWeight}
  ${ss.textAlign}
  ${ss.background}
  ${ss.system({
    transform: true,
    overflow: true,
  })}
  ${(props) =>
    props.$printHidden &&
    `
    @media print {
      display: none !important;
    }
  `}
`;
