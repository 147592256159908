import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import { CmsApiPage } from './types/CmsApiPage';
import { CmsApiSearchLandingPage } from './types/CmsApiSearchLandingPage';

type ApiResponse = {
  data: CmsApiPage | CmsApiSearchLandingPage;
  type: string;
};

function mapResponse(
  response: ApiResponse,
): CmsApiPage | CmsApiSearchLandingPage {
  return response.data;
}

const endpoint = new FetchEndpoint({
  urlFactory: (slug: string) => `/data/website/contents/${slug}`,
  mapResponse,
});

// Exports

export const cmsApiFetchPage = endpoint.createFetchFn();
