import queryString from 'query-string';
import { useCallback } from 'react';
import { useStore } from 'react-redux';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { searchConvertFiltersByIdToFiltersByName } from 'modules/search/helpers/searchConvertFiltersByIdToFiltersByName';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import { MainStoreState } from 'store/MainStoreState';

type Args = {
  isInSearchLandingPage: boolean;
};

export function useSearchPageActionsHelpers({ isInSearchLandingPage }: Args) {
  const getState = useStore().getState as () => MainStoreState;
  const navigate = useDeferredNavigate();

  const getDerivedSearchParamsFromState = useCallback(() => {
    const state = getState();

    const url = searchGetRoutePathSwitch({
      lang: CURRENT_LOCALE,
      q: state.mainSearch.query,
      filtersByName: searchConvertFiltersByIdToFiltersByName(
        state.mainSearch.filters,
      ),
      radius: state.searchRadius.value,
      sort: state.mainSearch.sort,
      pageIndex: state.mainSearch.pageIndex,
    });

    const analyticsSearchParams = queryString.parse(window.location.search);
    const newSearchParams = {
      ...analyticsSearchParams,
      ...queryString.parseUrl(url).query,
    };

    return queryString.stringify(newSearchParams);
  }, [getState]);

  /**
   * Set query params with only the given values
   */
  const resetQueryParams = useCallback(
    (
      values: Record<string, unknown>,
      locationState?: Record<string, unknown>,
    ) => {
      const pathname = isInSearchLandingPage
        ? searchGetRoutePathSwitch({
            lang: CURRENT_LOCALE,
            q: getState().mainSearch.query,
            filtersByName: { type: getState().mainSearch.filters.type },
          }).split('?')[0]
        : window.location.pathname;

      navigate(
        {
          pathname,
          search: queryString.stringify(values),
        },
        { state: locationState, replace: true },
      );
    },
    [getState, isInSearchLandingPage, navigate],
  );

  /**
   * Update query params, carrying over previous values
   */
  const updateQueryParams = useCallback(
    (
      values: Record<
        string,
        string | string[] | Record<string, unknown> | number | null | undefined
      >,
      locationState?: Record<string, unknown>,
    ) => {
      const newQueryParams = { ...values };
      const previousQueryParams = queryString.parse(
        isInSearchLandingPage
          ? getDerivedSearchParamsFromState()
          : window.location.search,
      );

      // `actionDate` is different from the other filters, it is an object filter, so we need special handling for it
      const { actionDate } = newQueryParams;
      if (actionDate && typeof actionDate === 'object') {
        if ('startsLT' in actionDate && actionDate.startsLT)
          newQueryParams.startsLT = actionDate.startsLT as string;

        if ('endsGT' in actionDate && actionDate.endsGT)
          newQueryParams.endsGT = actionDate.endsGT as string;

        delete newQueryParams.actionDate;

        // Avoid actionDate url params getting re-added by the previous query params
        delete previousQueryParams.startsLT;
        delete previousQueryParams.endsGT;
      }

      // If the new value is set explicitly to `undefined`, don't bring over the previous query param
      Object.keys(values).forEach((key) => {
        const value = values[key];
        if (typeof value === 'undefined') delete previousQueryParams[key];
      });

      resetQueryParams(
        { ...previousQueryParams, ...newQueryParams },
        locationState,
      );
    },
    [getDerivedSearchParamsFromState, isInSearchLandingPage, resetQueryParams],
  );

  return { getState, updateQueryParams, resetQueryParams };
}
