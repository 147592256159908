import type { SearchResponse } from '@algolia/client-search';

import { ApiJobSynonymFamilyId } from 'api/types/ApiTypedId';
import { SalariesJobSynonym } from 'modules/salaries/types/SalariesJobSynonym';
import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { isLocationEmpty } from 'modules/search/helpers/sidefilters';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';

import { MainStoreMainSearchState, SortType } from './MainStoreMainSearchState';

// Types

export type MainSearchSearchJobFamilyFacetData = {
  type: 'SEARCH_SET_JOB_FAMILY_FACET_DATA';
  jobFamilyFacetData: {
    familyId: ApiJobSynonymFamilyId;
    jobSynonym: SalariesJobSynonym;
  };
};

export type SetQueryAction = {
  type: 'SEARCH_SET_QUERY';
  query: string;
};

export type SetSortAction = {
  type: 'SEARCH_SET_SORT';
  sortParam: SortType;
};

export type SetPageIndexAction = {
  type: 'SEARCH_SET_PAGE_INDEX';
  pageIndex: number;
};

export type SetFiltersAction = {
  type: 'SEARCH_SET_FILTERS';
  filters: SearchFiltersById;
};

export type SearchStartedAction = {
  type: 'SEARCH_STARTED';
};

export type SuccessAction = {
  type: 'SEARCH_RESULTS_SUCCESS';
  results: SearchResponse<SearchAlgoliaHit>;
};

export type FetchPageSuccessAction = {
  type: 'SEARCH_FETCH_PAGE_SUCCESS';
  results: SearchResponse<SearchAlgoliaHit>;
};

export type UpdateSearchLocationAction = {
  type: 'UPDATE_SEARCH_LOCATION';
  location: SearchLocation;
};

export type MainStoreMainSearchAction =
  | MainSearchSearchJobFamilyFacetData
  | SetQueryAction
  | SetSortAction
  | SetPageIndexAction
  | SetFiltersAction
  | SearchStartedAction
  | SuccessAction
  | FetchPageSuccessAction
  | UpdateSearchLocationAction;

// Reducer

const initialState: MainStoreMainSearchState = {
  query: '',
  pageIndex: 0,
  resultsByPage: {},
  isSearching: false,
  sort: 'relevance',
  jobFamilyFacetData: undefined,
  filters: { type: 'JOB' },
  searchLocation: undefined,
};

export function mainSearchReducer(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MainStoreMainSearchState = initialState,
  action: MainStoreMainSearchAction,
): MainStoreMainSearchState {
  switch (action.type) {
    case 'SEARCH_SET_JOB_FAMILY_FACET_DATA':
      return {
        ...state,
        jobFamilyFacetData: action.jobFamilyFacetData,
        filters: {
          ...state.filters,
          jobFamilyFacet: [action.jobFamilyFacetData.familyId],
        },
      };

    case 'SEARCH_SET_QUERY': {
      return {
        ...state,
        pageIndex: 0,
        query: action.query,
      };
    }

    case 'SEARCH_SET_SORT': {
      return {
        ...state,
        pageIndex: 0,
        sort: action.sortParam,
      };
    }

    case 'SEARCH_SET_PAGE_INDEX': {
      return { ...state, pageIndex: action.pageIndex };
    }

    case 'SEARCH_SET_FILTERS': {
      return { ...state, filters: action.filters };
    }

    case 'SEARCH_STARTED': {
      return { ...state, isSearching: true };
    }

    case 'SEARCH_RESULTS_SUCCESS': {
      return {
        ...state,
        resultsByPage: { [action.results.page]: action.results },
        isSearching: false,
      };
    }

    case 'SEARCH_FETCH_PAGE_SUCCESS': {
      return {
        ...state,
        resultsByPage: {
          ...state.resultsByPage,
          [action.results.page]: action.results,
        },
        isSearching: false,
      };
    }

    case 'UPDATE_SEARCH_LOCATION':
      return {
        ...state,
        searchLocation: {
          ...action.location,
          hasGeo: !isLocationEmpty(action.location),
        },
      };
  }

  return state;
}
