import { del } from 'utils/http/del';
import { postJson } from 'utils/http/postJson';

export function apiCreateSavedListing(id: string, type: string) {
  return postJson(`/data/saved-listings`, { id, type });
}

export function apiDeleteSavedListing(id: string, type: string) {
  return del(`/data/saved-listings/${type}/${id}`);
}
