import { postJson } from 'utils/http/postJson';

import {
  ApiABExperiment,
  ApiABExperimentBucketForId,
  ApiABExperimentId,
} from './types/ApiABExperiment';

export async function apiSetABExperimentBucket<
  TExperimentId extends ApiABExperimentId,
>(
  experimentId: TExperimentId,
  bucket?: ApiABExperimentBucketForId<TExperimentId>,
): Promise<ApiABExperiment<TExperimentId>[]> {
  const { abExperiments } = await postJson<{
    abExperiments: ApiABExperiment<TExperimentId>[];
  }>('/data/ab/assignments', { id: experimentId, bucket });

  return abExperiments;
}
