import { Store } from '@reduxjs/toolkit';

import { ApiUserEnvironment } from 'api/userEnvironment/types/ApiUserEnvironment';
import { searchGetInitialLocation } from 'modules/search/helpers/searchGetInitialLocation';
import { StaffStoreState } from 'modules/staff/store/StaffStoreState';
import { MainStoreState } from 'store/MainStoreState';
import { updateUserEnvironment } from 'store/ducks/userEnvironment.actions';
import { updateSearchLocation } from 'store/mainSearch/mainSearch.actions';
import { assignAnalyticsAbExperiments } from 'utils/analytics/abExperiments/analyticsAbExperiments';
import { configureAnalyticsUserData } from 'utils/analytics/setup/configureAnalyticsUserData';
import { configureTrackJs } from 'utils/configureTrackJs';
import { trackReferrals } from 'utils/trackReferral';

import { renderingClientPerformanceMark } from './renderingClientPerformanceMark';
import { renderingOverrideClientABExperiments } from './renderingOverrideClientABExperiments';

type Args = {
  store: Store<MainStoreState | StaffStoreState>;
  userEnvironment: ApiUserEnvironment;
};

export function onRenderingClientUserEnvironmentLoaded({
  store,
  userEnvironment,
}: Args) {
  store.dispatch(updateUserEnvironment(userEnvironment));

  const { searchLocation } = store.getState().mainSearch;

  if (!searchLocation) {
    store.dispatch(
      // @ts-expect-error fix types
      updateSearchLocation(
        searchGetInitialLocation(userEnvironment.environment.guessedLocation),
        false,
      ),
    );
  }

  renderingClientPerformanceMark('Done setting login state');

  // Tracking
  configureTrackJs(userEnvironment.user ? userEnvironment.user.id : null);
  configureAnalyticsUserData({ userEnvironment });
  trackReferrals();

  // Configure AB experiments
  store.subscribe(() => {
    assignAnalyticsAbExperiments(
      store.getState().userEnvironment?.environment?.abExperiments || [],
    );
  });

  renderingOverrideClientABExperiments(store, userEnvironment);
  renderingClientPerformanceMark('User data first load');
}
