import {
  AppRouteOptionalParams,
  AppRouteRequiredParams,
} from './types/AppRouteParams';

export class AppRoute<
  TGroupPath extends string,
  TRelativePath extends string,
  TSearchParam extends string,
> {
  public readonly groupPath: TGroupPath;

  public readonly relativePath: TRelativePath;

  public readonly searchParams: TSearchParam[];

  constructor(data: {
    groupPath: TGroupPath;
    relativePath: TRelativePath;
    searchParams?: TSearchParam[];
  }) {
    this.groupPath = data.groupPath;
    this.relativePath = data.relativePath;
    this.searchParams = data.searchParams || [];
  }

  get fullPath(): TRelativePath extends ''
    ? TGroupPath
    : `${TGroupPath}/${TRelativePath}` {
    if (this.relativePath === '') {
      // @ts-expect-error The return type of `fullPath` is correct but there's no way to properly type-check this return statement
      return this.groupPath;
    }

    // @ts-expect-error The return type of `fullPath` is correct but there's no way to properly type-check this return statement
    return `${this.groupPath}/${this.relativePath}`;
  }

  get params(): Array<{ type: 'required' | 'optional'; name: string }> {
    return this.fullPath
      .split('/')
      .filter((part) => part.startsWith(':'))
      .map((part) =>
        part.endsWith('?')
          ? { type: 'optional', name: part.slice(1, -1) }
          : { type: 'required', name: part.slice(1) },
      );
  }

  /**
   * Full path with params.
   *
   * The given params & searchParams are set to the url accordingly
   */
  with<
    TWithRequiredParams extends Record<
      AppRouteRequiredParams<TGroupPath | TRelativePath>,
      string | number | string[]
    >,
    TWithOptionalParams extends Partial<
      Record<
        AppRouteOptionalParams<TGroupPath | TRelativePath>,
        string | number | string[]
      >
    >,
    TWithSearchParamKey extends TSearchParam,
    TWithSearchParams extends Record<
      TWithSearchParamKey,
      string | number | string[] | boolean
    >,
    TParams = TWithRequiredParams & TWithOptionalParams & TWithSearchParams,
  >(params: TParams) {
    // Url

    const pathname = this.params.reduce((currentPath: string, param) => {
      const value = params[param.name as keyof TParams]?.toString() || '';

      // For optional parameters
      if (param.type === 'optional') {
        // Unless there's a value, remove the extra /
        if (value === '') return currentPath.replace(`/:${param.name}?`, '');
        return currentPath.replace(`:${param.name}?`, value);
      }

      return currentPath.replace(`:${param.name}`, value);
    }, this.fullPath);

    // Search

    const searchParamsObject = new URLSearchParams();
    const searchKeys = this.searchParams.filter(
      (key) => key in (params as Record<string, unknown>),
    );

    searchKeys.forEach((key) => {
      const value = params[key as string as keyof typeof params];
      if (Array.isArray(value)) {
        value.forEach((item) => searchParamsObject.append(key, item));
      } else {
        searchParamsObject.append(key, value as string);
      }
    });

    return searchKeys.length > 0
      ? `${pathname}?${searchParamsObject.toString()}`
      : pathname;
  }
}
