import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiFetchJob } from 'modules/listing/api/job/listingApiFetchJob';
import { ViewJobPageLoadable } from 'modules/listing/pages/ViewJob/ViewJobPageLoadable';
import { ViewUnpublishedJobPageLoadable } from 'modules/listing/pages/ViewUnpublishedJob/ViewUnpublishedJobPageLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { AppRouteObject } from 'routing/classes/types/AppRouteObject';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';

import { jobSegmentedRoutes } from './job.routes';

/**
 * Job routes
 *
 * These routes are separate in their own file to facilitate maintenance, and
 * they're used on `main.route-objects.tsx`
 */
export const jobRouteObjects = jobSegmentedRoutes.map((segmentedRoute) => ({
  inpRouteId: 'view-job',
  keepDataWhileLoadingSameRoute: true,
  path: segmentedRoute.fullPath,
  ...renderLoader({
    loader: async ({ params }: AppRouteLoaderArgs<typeof segmentedRoute>) => {
      const { jobIdDashSlug = '' } = params;

      const {
        userEnvironment: { user },
      } = global.store.getState();

      try {
        const [job] = await Promise.all([
          listingApiFetchJob(jobIdDashSlug.split('-')[0]),
          // It's possible this loadable won't be used if the job is unpublished
          // but in most cases optimistically loading it is best
          ViewJobPageLoadable.load(),
        ]);

        switch (job.type) {
          case 'JOB':
            return {
              type: job.type,
              job,
              handle: {
                hideFooterDivider: Boolean(!user),
                urlByLocale: job.url,
              } satisfies LayoutHandleProps,
            };
          case 'UNPUBLISHED_JOB':
            await ViewUnpublishedJobPageLoadable.load();
            return { type: 'UNPUBLISHED_JOB', unpublishedJob: job };
        }
      } catch (e) {
        return null;
      }
    },
    render: (data) => {
      switch (data?.type) {
        case undefined:
          return <NotFoundPageLoadable noLayout />;
        case 'JOB':
          return <ViewJobPageLoadable job={data.job} />;
        case 'UNPUBLISHED_JOB':
          return (
            <ViewUnpublishedJobPageLoadable
              unpublishedJob={data.unpublishedJob}
            />
          );
      }
    },
  }),
  handle: {
    defaultSearchType: 'JOB',
    showDesktopSearch: true,
  } satisfies LayoutHandleProps,
})) satisfies AppRouteObject[];
