import { Nullable } from 'types/Nullable';
import { del } from 'utils/http/del';
import { patchJson } from 'utils/http/patchJson';
import { putJson } from 'utils/http/putJson';

import { OrgDashboardApiOrg } from './types/OrgDashboardApiOrg';

export function orgDashboardApiUpdateOrg(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orgInfo: Record<string, any>,
): Promise<OrgDashboardApiOrg> {
  return putJson(`/data/orgdashboard/${orgInfo.id}/organization`, orgInfo).then(
    // @ts-expect-error TS(2345): Argument of type '({ org }: { org: any; }) => any'... Remove this comment to see the full error message
    ({ org }) => org,
  );
}

export function orgDashboardApiPartialUpdateOrg(
  orgInfo: Pick<OrgDashboardApiOrg, 'id'> &
    Nullable<Partial<OrgDashboardApiOrg>>,
): Promise<OrgDashboardApiOrg> {
  return patchJson(
    `/data/orgdashboard/${orgInfo.id}/organization`,
    orgInfo,
  ).then(
    // @ts-expect-error TS(2345): Argument of type '({ org }: { org: any; }) => any'... Remove this comment to see the full error message
    ({ org }) => org,
  );
}

export function orgDashboardApiDeleteOrg(orgId: string) {
  return del(`/data/orgdashboard/${orgId}/organization`);
}
