import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { SearchJobsPageLoadable } from 'modules/search/pages/Jobs/SearchJobsPageLoadable';
import { AppRouteObject } from 'routing/classes/types/AppRouteObject';
import { loadableLoader } from 'routing/utils/loadableLoader';

import { searchGetJobsRoutePath } from './helpers/searchGetJobsRoutePath';

const urlByLocale = {
  en: searchGetJobsRoutePath({ lang: 'en' }),
  es: searchGetJobsRoutePath({ lang: 'es' }),
  pt: searchGetJobsRoutePath({ lang: 'pt' }),
} as const satisfies Record<UserLocale, string>;

export const searchJobsRouteObjects = (['en', 'es', 'pt'] as const).map(
  (locale) => ({
    path: urlByLocale[locale],
    handle: {
      urlByLocale,
      darkBackground: true,
      defaultSearchType: 'JOB',
      hideFooterDivider: true,
      showDesktopSearch: true,
      isSearchPage: true,
    } satisfies LayoutHandleProps,
    loader: loadableLoader(SearchJobsPageLoadable),
    element: <SearchJobsPageLoadable />,
  }),
) satisfies AppRouteObject[];
