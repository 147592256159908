import { trackMixpanelLinks } from 'utils/analytics/helpers/mixpanel';
import { validateEvent } from 'utils/analytics/helpers/validateEvent';
import { windowDataLayerPush } from 'utils/analytics/helpers/windowDataLayerPush';
import { log } from 'utils/logging';

// only use for external links, not with react-router
export function setupLinkTracker(
  name: string,
  properties: Record<string, unknown> = {},
) {
  return (el: Element | null | undefined) => {
    if (el) {
      if (!el.hasAttribute('href') || el.getAttribute('href') === '#') {
        throw new Error('Attempted to track link with no url');
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      properties.url = el.getAttribute('href');
      const eventName = `Clicked: ${name}`;
      validateEvent(eventName, properties);
      trackMixpanelLinks(el, eventName, properties);

      const clickHandler = () => {
        windowDataLayerPush({
          event: eventName,
          ...properties,
        });
        log.info('Triggering analytics event', {
          event: eventName,
          ...properties,
        });
      };

      el.addEventListener('click', clickHandler);
      return clickHandler;
    }
  };
}
