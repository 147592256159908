import { ReactNode, useId } from 'react';

import { InputAffixLabel, InputContainer, InputElement } from './Input.styled';
import {
  InputAffixVariant,
  InputStatus,
  InputStyleVariant,
} from './Input.types';
import { InputProps } from './InputProps';

type Props = {
  inputProps: InputProps;
  prefix?: ReactNode;
  suffix?: ReactNode;
  hideSuffixWhenEmpty?: boolean;
  status?: InputStatus;
  affixVariant?: InputAffixVariant;
  styleVariant?: InputStyleVariant;
  fullHeight?: boolean;
  fullWidth?: boolean;
};

export function Input({
  inputProps,
  prefix,
  suffix,
  hideSuffixWhenEmpty,
  status = 'neutral',
  affixVariant = 'default',
  styleVariant = 'default',
  fullHeight,
  fullWidth,
}: Props) {
  const generatedId = `input-group-${useId()}`;
  const inputId = inputProps.id || generatedId;

  return (
    <InputContainer
      $status={status}
      $affixVariant={affixVariant}
      $styleVariant={styleVariant}
      $fullHeight={Boolean(fullHeight)}
      $fullWidth={Boolean(fullWidth)}
      $hideSuffixWhenEmpty={Boolean(suffix && hideSuffixWhenEmpty)}
    >
      {prefix && <InputAffixLabel htmlFor={inputId}>{prefix}</InputAffixLabel>}

      <InputElement
        aria-activedescendant={inputProps.ariaActiveDescendant}
        aria-autocomplete={inputProps.ariaAutocomplete}
        aria-controls={inputProps.ariaControls}
        aria-expanded={inputProps.ariaExpanded}
        aria-haspopup={inputProps.ariaHasPopup}
        aria-invalid={inputProps.ariaInvalid}
        aria-label={inputProps.ariaLabel}
        autoComplete={inputProps.autoComplete}
        autoFocus={inputProps.autoFocus}
        data-modal-focus={inputProps.dataModalFocus}
        data-qa-id={inputProps.qaId}
        required={inputProps.required}
        disabled={inputProps.disabled}
        id={inputId}
        max={inputProps.max}
        min={inputProps.min}
        maxLength={inputProps.maxLength}
        name={inputProps.name}
        onBlur={inputProps.onBlur}
        onChange={inputProps.onChange}
        onClick={inputProps.onClick}
        onFocus={inputProps.onFocus}
        onKeyDown={inputProps.onKeyDown}
        onKeyPress={inputProps.onKeyPress}
        onKeyUp={inputProps.onKeyUp}
        placeholder={inputProps.placeholder}
        readOnly={inputProps.readOnly}
        role={inputProps.role}
        ref={inputProps.ref}
        step={inputProps.step}
        title={inputProps.title}
        type={inputProps.type}
        inputMode={inputProps.inputMode}
        value={inputProps.value}
        defaultValue={inputProps.defaultValue}
      />

      {suffix && <InputAffixLabel htmlFor={inputId}>{suffix}</InputAffixLabel>}
    </InputContainer>
  );
}
