import { OrgDashboardApiListing } from 'modules/orgDashboard/api/types/OrgDashboardApiListing';

// Types

export type MainStoreOrgDashboardListingsListing = OrgDashboardApiListing & {
  isFetching: boolean;
};

export type MainStoreOrgDashboardListingsState = {
  byId: Record<string, MainStoreOrgDashboardListingsListing>;
};

export type FetchListingRequestAction = {
  type: 'ORG_DASHBOARD_FETCH_LISTING_REQUEST';
  listingId: string;
};

export type FetchListingSuccessAction = {
  type: 'ORG_DASHBOARD_FETCH_LISTING_SUCCESS';
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listing: Record<string, any>;
};

export type ResetListingAction = {
  type: 'ORG_DASHBOARD_RESET_LISTING';
};

export type MainStoreOrgDashboardListingsAction =
  | FetchListingRequestAction
  | FetchListingSuccessAction
  | ResetListingAction;

// Reducer

const initialState: MainStoreOrgDashboardListingsState = {
  byId: {},
};

export function listingsReducer(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MainStoreOrgDashboardListingsState = initialState,
  action: MainStoreOrgDashboardListingsAction,
) {
  switch (action.type) {
    case 'ORG_DASHBOARD_FETCH_LISTING_REQUEST':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.listingId]: {
            ...state.byId[action.listingId],
            id: action.listingId,
            isFetching: true,
          },
        },
      };

    case 'ORG_DASHBOARD_FETCH_LISTING_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.listing.id]: { ...action.listing, isFetching: false },
        },
      };

    case 'ORG_DASHBOARD_RESET_LISTING':
      return initialState;

    default:
      return state;
  }
}
