import { OrgDashboardApiIntegrationJob } from 'modules/orgDashboard/api/types/OrgDashboardApiIntegrationJob';
import { OrgDashboardApiIntegrationProvider } from 'modules/orgDashboard/api/types/OrgDashboardApiIntegrationProvider';

// Types

export type Integration = {
  isFetching: boolean;
  jobs?: Array<OrgDashboardApiIntegrationJob> | null | undefined;
};

export type Integrations = {
  jazzhr?: Integration;
  greenhouse?: Integration;
};

export type MainStoreOrgDashboardIntegrationsState = {
  byId: Record<string, Integrations>;
};

export type FetchIntegrationAction = {
  type: 'FETCH_INTEGRATION_ACTION';
  integrationType: OrgDashboardApiIntegrationProvider;
  orgId: string;
};

export type FetchIntegrationFailureAction = {
  type: 'FETCH_INTEGRATION_FAILURE_ACTION';
  integrationType: OrgDashboardApiIntegrationProvider;
  orgId: string;
};

export type FetchIntegrationSuccessAction = {
  type: 'FETCH_INTEGRATION_SUCCESS_ACTION';
  integrationType: OrgDashboardApiIntegrationProvider;
  jobs: Array<OrgDashboardApiIntegrationJob> | null | undefined;
  orgId: string;
};

export type MainStoreOrgDashboardIntegrationsAction =
  | FetchIntegrationSuccessAction
  | FetchIntegrationAction
  | FetchIntegrationFailureAction;

// Reducer

const initialState = {
  byId: {},
};

export function integrationsReducer(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MainStoreOrgDashboardIntegrationsState = initialState,
  action: MainStoreOrgDashboardIntegrationsAction,
) {
  switch (action.type) {
    case 'FETCH_INTEGRATION_ACTION': {
      const orgIntegrations = { ...state.byId[action.orgId] };
      orgIntegrations[action.integrationType] = {
        jobs:
          // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
          orgIntegrations && orgIntegrations[action.integrationType]
            ? // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              orgIntegrations[action.integrationType].jobs
            : [],
        isFetching: true,
      };
      return {
        ...state,
        byId: { ...state.byId, [action.orgId]: orgIntegrations },
      };
    }

    case 'FETCH_INTEGRATION_FAILURE_ACTION': {
      const orgIntegrations = { ...state.byId[action.orgId] };
      orgIntegrations[action.integrationType] = {
        jobs: [],
        isFetching: false,
      };
      return {
        ...state,
        byId: { ...state.byId, [action.orgId]: orgIntegrations },
      };
    }

    case 'FETCH_INTEGRATION_SUCCESS_ACTION': {
      const orgIntegrations = { ...state.byId[action.orgId] };
      orgIntegrations[action.integrationType] = {
        jobs: action.jobs,
        isFetching: false,
      };
      return {
        ...state,
        byId: { ...state.byId, [action.orgId]: orgIntegrations },
      };
    }

    default:
      return state;
  }
}
