import { ApiPreferredListingType } from 'api/types/ApiPreferredListingType';
import { ApiOrgSize } from 'modules/listing/api/org/types/ApiOrgSize';
import { OrgDashboardApiOrg } from 'modules/orgDashboard/api/types/OrgDashboardApiOrg';

// Types

export type MainStoreOrgDashboardOrgsOrg = OrgDashboardApiOrg & {
  isFetching: boolean;
};

export type OrgUserPreferences = {
  preferredListingTypes: ApiPreferredListingType[] | undefined;
  orgSize: ApiOrgSize | undefined;
};

export type MainStoreOrgDashboardOrgsState = {
  byId: Record<string, MainStoreOrgDashboardOrgsOrg>;
  orgUserPreferences: OrgUserPreferences | undefined;
};

export type FetchOrgRequestAction = {
  type: 'ORG_DASHBOARD_FETCH_ORG_REQUEST';
  orgId: string;
};

export type FetchOrgSuccessAction = {
  type: 'ORG_DASHBOARD_FETCH_ORG_SUCCESS';
  org: OrgDashboardApiOrg;
  user: {
    preferredListingTypes: ApiPreferredListingType[];
    orgSize: ApiOrgSize;
  };
};

export type DeleteOrgAction = {
  type: 'ORG_DASHBOARD_DELETE_ORG';
  orgId: string;
};

export type UpdateOrgAction = {
  type: 'ORG_DASHBOARD_UPDATE_ORG';
  org: OrgDashboardApiOrg;
};

export type PartialUpdateOrgAction = {
  type: 'ORG_DASHBOARD_PARTIAL_UPDATE_ORG';
  values: Partial<OrgDashboardApiOrg>;
};

export type UpdateOrgUserPreferences = {
  type: 'ORG_DASHBOARD_UPDATE_ORG_USER_PREFERENCES';
  orgUserPreferences: OrgUserPreferences;
};

export type MainStoreOrgDashboardOrgsAction =
  | FetchOrgRequestAction
  | FetchOrgSuccessAction
  | DeleteOrgAction
  | UpdateOrgAction
  | PartialUpdateOrgAction
  | UpdateOrgUserPreferences;

// Reducer

export function orgsReducer(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MainStoreOrgDashboardOrgsState = {
    byId: {},
    orgUserPreferences: undefined,
  },
  action: MainStoreOrgDashboardOrgsAction,
) {
  switch (action.type) {
    case 'ORG_DASHBOARD_FETCH_ORG_REQUEST':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.orgId]: {
            ...state.byId[action.orgId],
            id: action.orgId,
            isFetching: true,
          },
        },
      };

    case 'ORG_DASHBOARD_FETCH_ORG_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.org.id]: {
            ...action.org,
            isFetching: false,
          },
        },
        orgUserPreferences: action.user,
      };

    case 'ORG_DASHBOARD_DELETE_ORG': {
      const newState = { ...state };
      delete newState.byId[action.orgId];
      return newState;
    }

    case 'ORG_DASHBOARD_UPDATE_ORG': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.org.id]: {
            ...state.byId[action.org.id],
            ...action.org,
          },
        },
      };
    }

    case 'ORG_DASHBOARD_PARTIAL_UPDATE_ORG': {
      return {
        ...state,
        byId: {
          ...state.byId,
          // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
          [action.values.id]: {
            // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
            ...state.byId[action.values.id],
            ...action.values,
          },
        },
      };
    }

    case 'ORG_DASHBOARD_UPDATE_ORG_USER_PREFERENCES': {
      return {
        ...state,
        orgUserPreferences: action.orgUserPreferences,
      };
    }

    default:
      return state;
  }
}
