import type { SearchResponse } from '@algolia/client-search';
import invariant from 'invariant';

import {
  ApiFilestackImageData,
  ApiFilestackImageWithTextData,
} from 'api/types/ApiFilestackImageData';
import { ApiISODateTimeNoTzString } from 'api/types/ApiTypedDate';
import { postSearchGetInitialFilters } from 'modules/postSearch/helpers/postSearchGetInitialFilters';
import { PostSearchFacetData } from 'modules/postSearch/types/PostSearchFacetData';
import { PostSearchQueryFilters } from 'modules/postSearch/types/PostSearchQueryFilters';
import { filterObject, isEmpty } from 'utils/functional';
import { truncateToUTF8Bytes } from 'utils/string/truncateToUTF8Bytes';

// Types

export type DynamicFacet = {
  fetchFn: () => Promise<unknown>;
  facetKey: PostSearchFacetKeys;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseToOptions: ({ categories }: any) => any;
};

export type SetQueryAction = {
  type: 'POST_SEARCH_SET_QUERY';
  query: string;
};

export type SearchSuccessAction = {
  type: 'POST_SEARCH_RESULTS_SUCCESS';
  results: MainStorePostSearchStateResults;
};

export type SearchStartedAction = {
  type: 'POST_SEARCH_STARTED';
};

export type SetPageIndexAction = {
  type: 'POST_SEARCH_SET_PAGE_INDEX';
  pageIndex: number;
};

export type InitializeSearchAction = {
  type: 'POST_SEARCH_INITIALIZE';
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams: Record<string, any>;
};

export type UninitializeSearchAction = {
  type: 'POST_SEARCH_UNINITIALIZE';
};

export type ClearSearchResultsAction = {
  type: 'POST_SEARCH_CLEAR_RESULTS';
};

export type ResetPageAction = {
  type: 'POST_SEARCH_RESET_PAGE';
};

export type ResetFiltersAction = {
  type: 'POST_SEARCH_RESET_FILTERS';
};

export type SetSearchFilter = {
  type: 'POST_SEARCH_SET_FILTER';
  key: string;
  value: string | null | undefined;
};

export type SelectFilterOption = {
  type: 'POST_SEARCH_SELECT_FILTER_OPTION';
  key: string;
  option: string | null | undefined;
};

export type DeselectFilterOption = {
  type: 'POST_SEARCH_DESELECT_FILTER_OPTION';
  key: string;
  option: string | null | undefined;
};

export type SelectAllFilterOption = {
  type: 'POST_SEARCH_SELECT_ALL_FILTER_OPTIONS';
  key: PostSearchFacetKeys;
};

export type SetFacetOptions = {
  type: 'POST_SEARCH_SET_FACET_OPTIONS';
  facetKey: PostSearchFacetKeys;
  options: Array<string>;
  optionTitles: Record<string, string>;
  optionsArray: Array<{
    id: string;
    parentId: string | null | undefined;
    icon: ApiFilestackImageData;
    slug: string;
    name: string;
  }>;
};

export type MainStorePostSearchAction =
  | SetQueryAction
  | SearchSuccessAction
  | SearchStartedAction
  | SetPageIndexAction
  | InitializeSearchAction
  | UninitializeSearchAction
  | ClearSearchResultsAction
  | ResetPageAction
  | ResetFiltersAction
  | SelectAllFilterOption
  | DeselectFilterOption
  | SetSearchFilter
  | SelectFilterOption
  | SetFacetOptions;

export type MainStorePostSearchStatePostHit = {
  author:
    | {
        image: ApiFilestackImageData;
        name: string;
        slug: string;
      }
    | null
    | undefined;
  categories: Array<string>;
  categoriesForCard: Array<string>;
  content: string;
  headerImage: ApiFilestackImageWithTextData;
  locale: UserLocale;
  objectID: string;
  postedDate: ApiISODateTimeNoTzString;
  showDate: boolean;
  subsiteId: Array<string>;
  subsiteName: string;
  tags: Array<string>;
  thumbnail: ApiFilestackImageWithTextData;
  title: string;
  url: string;
  type: string;
  metaDescription: string | null | undefined;
};

export type MainStorePostSearchStateResults =
  SearchResponse<MainStorePostSearchStatePostHit> & {
    readonly timestamp: number;
    readonly searchId: number;
  };

type PostSearchFilters = Omit<PostSearchQueryFilters, 'type'>;

export type PostSearchFacetKeys = keyof PostSearchFilters;

const postSearchFacets: Record<PostSearchFacetKeys, PostSearchFacetData> = {
  articleCategoriesFacet: {
    id: 'articleCategoriesFacet',
    name: 'categories',
    title: getText('Category'),
    type: 'array',
    lazy: true,
    optionsArray: [],
    options: [],
    // Lazy loaded
    optionTitles: {}, // Lazy loaded
  },
  articleAuthorFacet: {
    id: 'articleAuthorFacet',
    name: 'author.slug',
    title: getText('Author'),
    type: 'array',
    lazy: true,
    options: [],
    optionTitles: {},
  },
  articleTagsFacet: {
    id: 'articleTagsFacet',
    name: 'tags.slug',
    title: getText('Tags'),
    type: 'array',
    lazy: true,
    options: [],
    optionTitles: {},
  },
};

const initialFacetsThatArentLoaded: Array<string> = Object.keys(
  filterObject(postSearchFacets, (_, f) => Boolean(f.lazy)),
);

export type MainStorePostSearchState = {
  query: string;
  pageIndex: number;
  lastLoadedPageIndex: number;
  searchId: number;
  // Incrementing id
  resultsByPage: Record<string, MainStorePostSearchStateResults>;
  resultsForSearchId?: number | null | undefined;
  initialized: boolean;
  initialSearchStarted: boolean;
  isSearching: boolean;
  queryID: string | null | undefined;
  searchFacets: Record<PostSearchFacetKeys, PostSearchFacetData>;
  facetsThatArentLoaded: Array<string>;
  filters: PostSearchFilters;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postSearch?: any;
};

const initialSearchState: MainStorePostSearchState = {
  query: '',
  pageIndex: 0,
  lastLoadedPageIndex: 0,
  searchId: 0,
  // Incrementing id
  resultsByPage: {},
  initialized: false,
  initialSearchStarted: false,
  isSearching: false,
  queryID: null,
  searchFacets: postSearchFacets,
  filters: {
    articleAuthorFacet: [],
    articleTagsFacet: [],
    articleCategoriesFacet: [],
  },
  facetsThatArentLoaded: initialFacetsThatArentLoaded,
};

// Reducer

const defaultState: MainStorePostSearchState = initialSearchState;

export function postSearchReducer(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MainStorePostSearchState = defaultState,
  action: MainStorePostSearchAction,
) {
  switch (action.type) {
    case 'POST_SEARCH_SET_QUERY': {
      return {
        ...state,
        searchId: state.searchId + 1,
        pageIndex: 0,
        // New search, reset page
        query: action.query,
      };
    }

    case 'POST_SEARCH_RESET_PAGE': {
      return {
        ...state,
        searchId: state.searchId + 1,
        pageIndex: 0, // New search, reset page
      };
    }

    case 'POST_SEARCH_RESET_FILTERS': {
      const filters = {
        ...postSearchGetInitialFilters(state.searchFacets, {}),
        articleCategoriesFacet: state.filters.articleCategoriesFacet,
      };
      return {
        ...state,
        searchId: state.searchId + 1,
        pageIndex: 0,
        filters,
      };
    }

    case 'POST_SEARCH_SET_PAGE_INDEX': {
      return { ...state, pageIndex: action.pageIndex };
    }

    case 'POST_SEARCH_INITIALIZE': {
      const { page, pq } = action.queryParams;
      const authorSlug = action.queryParams['author.slug'];
      const tagsSlug = action.queryParams['tags.slug'];

      const facetsThatArentLoaded = initialFacetsThatArentLoaded.filter(
        (facet) =>
          (facet === 'articleTagsFacet' && tagsSlug) ||
          (facet === 'articleAuthorFacet' && authorSlug) ||
          facet === 'articleCategoriesFacet',
      );

      const filters: PostSearchFilters = postSearchGetInitialFilters(
        postSearchFacets,
        action.queryParams,
      );
      return {
        ...initialSearchState,
        facetsThatArentLoaded,
        initialized: true,
        query: pq ? truncateToUTF8Bytes(pq || '', 512) : state.query,
        pageIndex: page ? Number(page) - 1 : state.pageIndex,
        filters,
      };
    }

    case 'POST_SEARCH_UNINITIALIZE': {
      return {
        ...state,
        facetsThatArentLoaded: initialFacetsThatArentLoaded,
        initialized: false,
      };
    }

    case 'POST_SEARCH_STARTED': {
      return {
        ...state,
        initialSearchStarted: true,
        isSearching: true,
        queryID: null,
      };
    }

    case 'POST_SEARCH_RESULTS_SUCCESS': {
      // If the results are from an older search, ignore them
      const lastResults =
        state.resultsByPage[state.lastLoadedPageIndex.toString()];

      if (lastResults && lastResults.timestamp > action.results.timestamp) {
        return state;
      }

      const newSearch = action.results.searchId !== state.resultsForSearchId;
      return {
        ...state,
        queryID: action.results.queryID,
        resultsByPage: newSearch
          ? {
              [action.results.page]: action.results,
            }
          : { ...state.resultsByPage, [action.results.page]: action.results },
        lastLoadedPageIndex: action.results.page,
        resultsForSearchId: action.results.searchId,
        isSearching: false,
      };
    }

    case 'POST_SEARCH_CLEAR_RESULTS': {
      return { ...state, resultsByPage: {}, resultsForSearchId: null };
    }

    case 'POST_SEARCH_SET_FACET_OPTIONS': {
      const { options, optionTitles, optionsArray, facetKey } = action;
      const facet = state.searchFacets[facetKey];
      const facetsThatArentLoaded: Array<string> =
        state.facetsThatArentLoaded.filter((key) => key !== facetKey);

      if (!facet && isEmpty(options)) {
        return state;
      }

      // const sideFacets = sideFacetsForType(searchFacets, state.filters.type);
      // const hasFacet = sideFacets.find(f => f.id === facet.id);
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const filterValue = facet.id && state.filters[facet.id];
      const updatedFilters = facet.id
        ? { ...state.filters, [facet.id]: filterValue || options }
        : state.filters;
      const newSearchFacets: Record<string, PostSearchFacetData> = {
        // Consider all search facets, not just ones in the current state
        ...postSearchFacets,
        ...state.searchFacets,
        [facetKey]: {
          ...facet,
          options,
          optionTitles,
          optionsArray,
        },
      };
      return {
        ...state,
        // This needs to get bumped if we change filter values
        // But if we dont, then it doesnt need to get changed
        searchId:
          filterValue && filterValue !== options
            ? state.searchId + 1
            : state.searchId,
        facetsThatArentLoaded,
        // Only set the filter value if the current filter.type supports this
        // facet
        filters: updatedFilters,
        searchFacets: newSearchFacets,
      };
    }

    case 'POST_SEARCH_SET_FILTER': {
      const facet = action.key;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line prefer-destructuring
      const value = action.value;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line prefer-object-spread
      const filters = Object.assign(
        {}, // Setting the type filter resets the other filters.
        state.filters,
        {
          [facet]: value,
        },
      );
      return {
        ...state,
        searchId: state.searchId + 1,
        pageIndex: 0,
        // New search, reset page
        filters,
      };
    }

    case 'POST_SEARCH_SELECT_FILTER_OPTION': {
      const facet = action.key;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const filter = state.filters[facet];
      const selectedOptions = [
        ...((Array.isArray(filter) && filter) || []),
        action.option,
      ];
      return {
        ...state,
        searchId: state.searchId + 1,
        pageIndex: 0,
        // New search, reset page
        filters: { ...state.filters, [facet]: selectedOptions },
      };
    }

    case 'POST_SEARCH_DESELECT_FILTER_OPTION': {
      const facet = action.key;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line prefer-destructuring
      const option = action.option;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      invariant(Array.isArray(state.filters[facet]), 'Missing search filter');
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const selectedOptions = (state.filters[facet] as string[]).filter(
        (v) => v !== option,
      );
      return {
        ...state,
        searchId: state.searchId + 1,
        pageIndex: 0,
        // New search, reset page
        filters: { ...state.filters, [facet]: selectedOptions },
      };
    }

    case 'POST_SEARCH_SELECT_ALL_FILTER_OPTIONS': {
      const facet = action.key;
      return {
        ...state,
        searchId: state.searchId + 1,
        pageIndex: 0,
        // New search, reset page
        filters: {
          ...state.filters,
          [facet]: state.searchFacets[facet].options,
        },
      };
    }

    default:
      return state;
  }
}
