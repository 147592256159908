import { NonNullableFields } from 'types/NonNullableFields';
import { del } from 'utils/http/del';
import { getJson } from 'utils/http/getJson';
import { postJson } from 'utils/http/postJson';

import { ApiUserEnvironment } from './types/ApiUserEnvironment';

export function fetchUserEnvironment(): Promise<ApiUserEnvironment> {
  return getJson<ApiUserEnvironment>(`/data/environment`);
}

export function canDeleteAccount(): Promise<{
  isLastOwnerOfOrgs: Array<{
    id: string;
    name: string;
    hasOutstandingInvoices: boolean;
    credits: number;
  }>;
}> {
  return getJson<{
    isLastOwnerOfOrgs: Array<{
      id: string;
      name: string;
      hasOutstandingInvoices: boolean;
      credits: number;
    }>;
  }>('/data/users/me/can-delete');
}

export function deleteAccount() {
  return del('/data/users/me/delete');
}

export async function signUp(
  userInfo: Record<string, unknown>,
): Promise<NonNullableFields<ApiUserEnvironment>> {
  return postJson<NonNullableFields<ApiUserEnvironment>>(
    '/data/website/signup',
    userInfo,
  );
}

export async function logIn(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loginInfo: Record<string, any>,
): Promise<NonNullableFields<ApiUserEnvironment>> {
  return postJson<NonNullableFields<ApiUserEnvironment>>(
    '/data/website/login',
    loginInfo,
  );
}

export function forgotPassword(email: string): Promise<void> {
  return postJson('/data/website/forgot-password', {
    email,
  });
}

export async function resetPassword({
  password,
  token,
}: {
  password: string;
  token: string;
}): Promise<NonNullableFields<ApiUserEnvironment>> {
  return postJson<NonNullableFields<ApiUserEnvironment>>(
    `/data/website/reset-password/${token}`,
    {
      password,
    },
  );
}
