import { css } from 'styled-components';

import { TooltipPosition } from './TooltipPosition';
import { TooltipSize } from './TooltipSize';

export const brandVariantArrowPositionStyles = (
  placement: TooltipPosition,
  targetWidth: number,
  size: TooltipSize,
) => {
  const arrowSize = size === 'large' ? 20 : 10;
  const arrowHorizontalOffset = targetWidth < arrowSize ? 20 : 0;
  const arrowVerticalOffset = size === 'large' ? -17 : -7;

  switch (placement) {
    case 'bottom-center':
      return css`
        &::before,
        &::after {
          clip-path: polygon(
            calc(50% - ${arrowSize}px) 100%,
            50% 0,
            calc(50% + ${arrowSize}px) 100%
          );
          top: ${arrowVerticalOffset}px;
        }
      `;
    case 'bottom-left':
      return css`
        &::before,
        &::after {
          clip-path: polygon(
            calc(
                100% - ${targetWidth / 2}px - ${arrowSize}px -
                  ${arrowHorizontalOffset}px
              )
              100%,
            calc(100% - ${targetWidth / 2}px - ${arrowHorizontalOffset}px) 0%,
            calc(
                100% - ${targetWidth / 2}px + ${arrowSize}px -
                  ${arrowHorizontalOffset}px
              )
              100%
          );
          top: ${arrowVerticalOffset}px;
        }
      `;
    case 'bottom-right':
      return css`
        &::before,
        &::after {
          clip-path: polygon(
            calc(
                0% + ${targetWidth / 2}px + ${arrowSize}px +
                  ${arrowHorizontalOffset}px
              )
              100%,
            calc(0% + ${targetWidth / 2}px + ${arrowHorizontalOffset}px) 0%,
            calc(
                0% + ${targetWidth / 2}px - ${arrowSize}px +
                  ${arrowHorizontalOffset}px
              )
              100%
          );
          top: ${arrowVerticalOffset}px;
        }
      `;

    case 'top-center':
      return css`
        &::before,
        &::after {
          clip-path: polygon(
            calc(50% - ${arrowSize}px) 0,
            50% 100%,
            calc(50% + ${arrowSize}px) 0
          );
          bottom: ${arrowVerticalOffset}px;
        }
      `;
    case 'top-left':
      return css`
        &::before,
        &::after {
          clip-path: polygon(
            calc(
                100% - ${targetWidth / 2}px - ${arrowSize}px -
                  ${arrowHorizontalOffset}px
              )
              0%,
            calc(100% - ${targetWidth / 2}px - ${arrowHorizontalOffset}px) 100%,
            calc(
                100% - ${targetWidth / 2}px + ${arrowSize}px -
                  ${arrowHorizontalOffset}px
              )
              0%
          );
          bottom: ${arrowVerticalOffset}px;
          left: 0;
        }
      `;
    case 'top-right':
      return css`
        &::before,
        &::after {
          clip-path: polygon(
            calc(
                0% + ${targetWidth / 2}px + ${arrowSize}px +
                  ${arrowHorizontalOffset}px
              )
              0%,
            calc(0% + ${targetWidth / 2}px + ${arrowHorizontalOffset}px) 100%,
            calc(
                0% + ${targetWidth / 2}px - ${arrowSize}px +
                  ${arrowHorizontalOffset}px
              )
              0%
          );
          bottom: ${arrowVerticalOffset}px;
          left: 0;
        }
      `;
    default:
      return null;
  }
};
