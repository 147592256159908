import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { SearchEventsPageLoadable } from 'modules/search/pages/Events/SearchEventsPageLoadable';
import { AppRouteObject } from 'routing/classes/types/AppRouteObject';
import { loadableLoader } from 'routing/utils/loadableLoader';

import { searchGetEventsRoutePath } from './helpers/searchGetEventsRoutePath';

const urlByLocale = {
  en: searchGetEventsRoutePath({ lang: 'en' }),
  es: searchGetEventsRoutePath({ lang: 'es' }),
  pt: searchGetEventsRoutePath({ lang: 'pt' }),
} as const satisfies Record<UserLocale, string>;

export const searchEventsRouteObjects = (['en', 'es', 'pt'] as const).map(
  (locale) => ({
    path: urlByLocale[locale],
    handle: {
      urlByLocale,
      darkBackground: true,
      defaultSearchType: 'EVENT',
      hideFooterDivider: true,
      showDesktopSearch: true,
      isSearchPage: true,
    } satisfies LayoutHandleProps,
    loader: loadableLoader(SearchEventsPageLoadable),
    element: <SearchEventsPageLoadable />,
  }),
) satisfies AppRouteObject[];
