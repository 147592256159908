import { MutableRefObject, useEffect, useState } from 'react';

import { setupLinkTracker } from 'utils/analytics/setup/setupLinkTracker';

import { AnalyticsData } from './AnalyticsData';

type Args<T extends HTMLElement> = {
  ref: MutableRefObject<T> | undefined;
  analyticsData: AnalyticsData | undefined;
};

export function useTrackLinks<T extends HTMLElement>({
  ref,
  analyticsData,
}: Args<T>) {
  const [linksAreTracked, setLinksAreTracked] = useState(false);

  useEffect(() => {
    const { eventName, eventProperties } = analyticsData || {};

    if (!eventProperties || !eventName || !linksAreTracked) return;

    if (ref && ref.current) {
      const linkEls = [...ref.current.getElementsByTagName('a')];
      linkEls.forEach((link) => {
        if (link.hasAttribute('href') && link.getAttribute('href') !== '#') {
          const href = link.getAttribute('href') || '';
          setupLinkTracker(eventName, {
            ...eventProperties,
            url: href.split('?')[0],
          })(link);
        }
      });
    }

    setLinksAreTracked(true);
  }, [analyticsData, linksAreTracked, ref, setLinksAreTracked]);
}
