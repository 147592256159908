import { Box } from 'components/Box';
import { Divider } from 'components/Divider';
import { Image } from 'components/Image/Image';
import { Link } from 'components/Link';
import { IdealistLogoOnDemand } from 'components/Logo/IdealistLogoOnDemand';
import { Body } from 'components/Text/Body';
import { H1 } from 'components/Text/H1';
import { HeadingSmall } from 'components/Text/HeadingSmall';
import { gutterWidth } from 'theme/theme';
import { getStaticImageSrc } from 'utils/getImageSrc';

type Props = {
  searchText?: string;
  searchUrl?: string;
  homepageUrl?: string;
  textOnly?: boolean;
};

export function NotFoundContent({
  searchText,
  searchUrl,
  homepageUrl,
  textOnly,
}: Props) {
  return (
    // @ts-expect-error TS(2322): Type 'false | ("block" | "flex" | null)[]' is not ... Remove this comment to see the full error message
    <Box display={!textOnly && ['block', null, 'flex']} alignItems="center">
      <Box
        // @ts-expect-error TS(2322): Type 'false | (number | null)[]' is not assignable... Remove this comment to see the full error message
        width={!textOnly && [1, null, 1 / 2]}
        // @ts-expect-error TS(2322): Type 'false | (number | null)[]' is not assignable... Remove this comment to see the full error message
        pr={!textOnly && [0, null, gutterWidth]}
      >
        <HeadingSmall color="lightContentGrey">
          {'404: '}
          {getText('Page Not Found')}
        </HeadingSmall>

        <H1>
          {
            /* TRANSLATORS: 404 page title */
            getText(`Well, this isn't ideal...`)
          }
        </H1>

        <Body>
          {
            /* TRANSLATORS: 404 page copy */
            getText(`Sorry, we couldn't find the page you were looking for.
          The link you followed may be broken, or the page may have been removed.
          Try the following options:`)
          }
        </Body>

        <Box mt={(gutterWidth * 3) / 4}>
          <Link href={searchUrl || `/jobs`}>
            <Box display="flex" alignItems="center">
              <Box transform="translateY(3px)">
                <Image
                  src={getStaticImageSrc('magifying-glass.png')}
                  alt="magnifying glass"
                  width={24}
                />
              </Box>
              <Box ml={24}>
                {searchText ||
                  (searchUrl
                    ? getText('Search the site')
                    : getText('Search jobs'))}
              </Box>
            </Box>
          </Link>

          <Divider marginTop={gutterWidth / 2} marginBottom={gutterWidth / 2} />

          <Link href={homepageUrl || '/'}>
            <Box display="flex" alignItems="center">
              <Box transform="translateY(3px)">
                <IdealistLogoOnDemand height={24} />
              </Box>
              <Box ml={24}>{getText('Go to the homepage')}</Box>
            </Box>
          </Link>
        </Box>
      </Box>

      {!textOnly && (
        <Box
          position="relative"
          height="100%"
          width={[1, null, 1 / 2]}
          mt={[gutterWidth, null, 0]}
        >
          <Image src={getStaticImageSrc('not-found.svg')} alt="Illustration" />
        </Box>
      )}
    </Box>
  );
}
