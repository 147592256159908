import { UserDashboardApiPaymentMethod } from 'modules/userDashboard/api/types/UserDashboardApiPaymentMethod';
import { removeId } from 'utils/functional/array/removeId';

// Types

export type FetchPaymentMethodsRequestAction = {
  type: 'FETCH_PAYMENT_METHODS_REQUEST';
};

export type FetchPaymentMethodsSuccessAction = {
  type: 'FETCH_PAYMENT_METHODS_SUCCESS';
  methods: Array<UserDashboardApiPaymentMethod>;
};

export type AddPaymentMethodAction = {
  type: 'ADD_PAYMENT_METHOD';
  method: UserDashboardApiPaymentMethod;
};

export type RemovePaymentMethodAction = {
  type: 'REMOVE_PAYMENT_METHOD';
  methodId: string;
};

export type MainStorePaymentMethodsAction =
  | FetchPaymentMethodsRequestAction
  | FetchPaymentMethodsSuccessAction
  | AddPaymentMethodAction
  | RemovePaymentMethodAction;

export type MainStorePaymentMethodsState = {
  methods: UserDashboardApiPaymentMethod[];
  isFetching: boolean;
};

// Reducer

export function paymentMethodsReducer(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MainStorePaymentMethodsState = {
    methods: [],
    isFetching: false,
  },
  action: MainStorePaymentMethodsAction,
) {
  switch (action.type) {
    case 'FETCH_PAYMENT_METHODS_REQUEST':
      return { ...state, isFetching: true };

    case 'FETCH_PAYMENT_METHODS_SUCCESS':
      return { ...state, isFetching: false, methods: action.methods };

    case 'ADD_PAYMENT_METHOD': {
      const { methods } = state;
      return { ...state, methods: [...methods, action.method] };
    }

    case 'REMOVE_PAYMENT_METHOD': {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line prefer-destructuring
      const methodId = action.methodId;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line prefer-destructuring
      const methods = state.methods;

      if (!methods) {
        return state;
      }

      return { ...state, methods: removeId(methods, methodId) };
    }

    default:
      return state;
  }
}
