import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { isNonAdmin, isSalarySurveyCompleted } from 'utils/userEnvironment';

export function useShouldPromoteSalarySurvey(): boolean {
  const { user, environment, isUserLoaded, isEnvironmentLoaded } =
    useUserEnvironment();

  if (!isUserLoaded || !isEnvironmentLoaded) {
    return false;
  }

  const { isUS } = environment || {};

  if (CURRENT_LOCALE !== 'en' || !isUS) {
    return false;
  }

  if (isSalarySurveyCompleted(user)) {
    return false;
  }

  if (user && !isNonAdmin(user)) {
    return false;
  }

  return true;
}
