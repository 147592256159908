import { useEffect, useRef } from 'react';
import { useRevalidator } from 'react-router-dom';

import { useIsLoggedIn } from 'store/hooks/useIsLoggedIn';

export const cmsRouteRevalidation = {
  // After logging in we need to revalidate the CMS route because the contents
  // may change based on whether the user is `logged in`, `recognized` or `unrecognized`
  force: false,
};

export function useCmsPageRevalidateAfterUserAuthentication() {
  const revalidator = useRevalidator();
  const isLoggedIn = useIsLoggedIn();
  const wasLoggedInRef = useRef(isLoggedIn);

  useEffect(() => {
    if (isLoggedIn !== wasLoggedInRef.current) {
      cmsRouteRevalidation.force = true;
      revalidator.revalidate();
      wasLoggedInRef.current = isLoggedIn;
    }
  }, [isLoggedIn, revalidator, wasLoggedInRef]);

  useEffect(() => {
    if (revalidator.state === 'idle') cmsRouteRevalidation.force = false;
  }, [revalidator.state]);
}
