import styled from 'styled-components';

import { LegacyButton as Button } from 'components/Button/LegacyButton';

import { buttonStyles } from './buttonStyles';

export const ButtonWithStyles = styled(Button).attrs((props) => ({
  type: props.type === undefined ? 'button' : props.type,
}))<{ $size?: 'small' | 'medium' | 'large' }>`
  ${buttonStyles};
`;
