import { combineReducers } from '@reduxjs/toolkit';

import { integrationsReducer as integrations } from './integrations';
import { listingsReducer } from './listings';
import { orgsReducer as orgs } from './orgs';

export const mainStoreOrgDashboardReducer = combineReducers({
  orgs,
  listings: listingsReducer,
  integrations,
});
