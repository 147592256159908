import { CmsContentPageLoadable } from 'modules/cms/containers/ContentPage/CmsContentPageLoadable';
import { PostSearchPageLoadable } from 'modules/postSearch/pages/PostSearch/PostSearchPageLoadable';
import { SearchLandingPageLoadable } from 'modules/search/pages/Landing/SearchLandingPageLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import { AppRouteObject } from 'routing/classes/types/AppRouteObject';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';

import { cmsPageLoader } from './cmsPageLoader';
import { cmsRouteShouldRevalidate } from './cmsRouteShouldRevalidate';

export const cmsRouteId = 'cms-route-';

export const cmsRouteObjects = [
  {
    id: cmsRouteId,
    path: '/*',
    ...renderLoader({
      loader: cmsPageLoader,
      render: function CmsPageSwitch(data) {
        switch (data?.type) {
          case undefined:
            return <NotFoundPageLoadable noLayout />;
          case 'CONTENT':
            return <CmsContentPageLoadable content={data.content} />;
          case 'SEARCH_LANDING_PAGE':
            return (
              <SearchLandingPageLoadable
                content={data.content}
                isSsr={data.isSsr}
              />
            );
          case 'POST_SEARCH':
            return <PostSearchPageLoadable content={data.content} />;
        }
      },
    }),
    shouldRevalidate: cmsRouteShouldRevalidate,
  },
] satisfies AppRouteObject[];
