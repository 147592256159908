import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiFetchOrg } from 'modules/listing/api/org/listingApiFetchOrg';
import { ViewOrgPageLoadable } from 'modules/listing/pages/ViewOrg/ViewOrgPageLoadable';
import { ViewUnpublishedOrgPageLoadable } from 'modules/listing/pages/ViewUnpublishedOrg/ViewUnpublishedOrgPageLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';
import { MainStoreState } from 'store/MainStoreState';

import { orgSegmentedRoutes } from './org.routes';

export const orgRouteObjects = orgSegmentedRoutes.map((segmentedRoute) => ({
  inpRouteId: 'view-org',
  keepDataWhileLoadingSameRoute: true,
  path: segmentedRoute.fullPath,
  ...renderLoader({
    loader: async ({
      params,
    }: AppRouteLoaderArgs<(typeof orgSegmentedRoutes)[0]>) => {
      const state = global.store.getState() as MainStoreState;

      const {
        userEnvironment: { user },
      } = state;

      // It's possible this loadable won't be used if the job is unpublished
      // but in most cases optimistically loading it is best
      await ViewOrgPageLoadable.load();

      const orgId = (params.orgIdDashSlug as string).split('-')[0];

      const org = await listingApiFetchOrg(orgId);

      const hideFooterDivider = Boolean(!user);

      switch (org?.type) {
        case undefined:
          return null;
        case 'ORG':
          return {
            type: 'ORG',
            org,
            handle: {
              hideFooterDivider,
              urlByLocale: org.url,
            } satisfies LayoutHandleProps,
          } as const;
        case 'UNPUBLISHED_ORG':
          return {
            type: 'UNPUBLISHED_ORG',
            unpublishedOrg: org,
            handle: { hideFooterDivider } satisfies LayoutHandleProps,
          } as const;
      }
    },
    render: (data) => {
      switch (data?.type) {
        case undefined:
          return <NotFoundPageLoadable noLayout />;
        case 'ORG':
          if (data.org.isFrozen) {
            return <ViewUnpublishedOrgPageLoadable unpublishedOrg={data.org} />;
          }

          return (
            <ViewOrgPageLoadable org={data.org} isPublished isPreview={false} />
          );
        case 'UNPUBLISHED_ORG':
          return (
            <ViewUnpublishedOrgPageLoadable
              unpublishedOrg={data.unpublishedOrg}
            />
          );
      }
    },
  }),
  handle: {
    defaultSearchType: 'ORG',
    showDesktopSearch: true,
  },
}));
