import { create } from 'zustand';

export type AdType = null | 'SALARY_SURVEY' | 'RESUME_DB' | 'GRAD_FAIRS';

const adStore = create<{ adType: AdType | null }>(() => ({
  adType: null,
}));

const useAdStore = adStore; // The store can be used as a hook

// Action

export function setAdType(adType: AdType) {
  adStore.setState({ adType });
}

// Hook

export function useAdType() {
  const adType = useAdStore((state) => state.adType);
  return { adType };
}
